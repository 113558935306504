import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    Typography,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Button,
    Grid
} from "@material-ui/core"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from 'react'
import {withFirebase} from '../../../Firebase';
import NumberFormatter from '../../../General/NumberFormatter'

import ConfirmKnowledge from './ConfirmKnowledge';

class Acknowledge extends React.Component {
    constructor(props) {
        super(props);
        this.transactionId = this.props.token;

        this.state = {
            authorized: false,
            carInfo: {},
            sellerInfo: {},
            disclosures: [],
            price: 0,
            open: false
        }
        this
            .props
            .firebase
            .sellerCarInfo(this.transactionId)
            .once("value")
            .then(snapshot => {
                const sval = snapshot.val();
                this.setState({carInfo: sval || {}});
            });
        this
            .props
            .firebase
            .disclosures(this.transactionId)
            .once("value")
            .then(snapshot => {
                const sval = snapshot.val();
                this.setState({disclosures: sval || []});
            });

        this
            .props
            .firebase
            .sellerBasicInfo(this.transactionId)
            .once("value")
            .then(snapshot => {
                const sval = snapshot.val();
                this.setState({sellerInfo: sval || {}});
            });
        this
            .props
            .firebase
            .buyerReady(this.transactionId)
            .once("value")
            .then(snapshot => {
                const sval = snapshot.val();
                this.setState({authorized: sval});
                this.props.onConfirm(sval);
            });

        this
            .props
            .firebase
            .price(this.transactionId)
            .once("value")
            .then(snapshot => {
                const sval = snapshot.val();
                this.setState({price: sval});
            });

        this.handleChange = this
            .handleChange
            .bind(this);
        this.confirmCheck = this
            .confirmCheck
            .bind(this);

        this.handleAgree = this
            .handleAgree
            .bind(this);
        this.handleClose = this
            .handleClose
            .bind(this);

    }
    confirmCheck() {
        //open modal and return true if confirmation
        this.setState({open: true});
    }

    handleChange(event) {
        this.confirmCheck();
    }

    handleClose() {
        this.setState({open: false, authorized: false});
    }

    handleAgree() {
        this.setState({open: false, authorized: true});
        this.props.onConfirm(true);
        fetch('/api/selleragree', {
            method: 'POST',
            body: JSON.stringify({transactionId: this.props.token})
        });

    }

    getTableConstants(){
        let tableConstants = [];
        let name = this.state.sellerInfo.firstname + " " + this.state.sellerInfo.lastname;
        name = (this.state.sellerInfo.firstname && this.state.sellerInfo.lastname)? name: ""
        tableConstants.push(["Name", name]);    

        let address =  this.state.sellerInfo.address1;
        if(this.state.address2){
            address += " " + this.state.sellerInfoaddress2;
        }
        address += " " + this.state.sellerInfo.city + ", " + this.state.sellerInfo.state + " " + this.state.sellerInfo.zip
        tableConstants.push(["Address", address]);

        let carName = this.state.carInfo.year + " " + this.state.carInfo.make + " " + this.state.carInfo.model; 
        tableConstants.push(["Car", carName]);

        tableConstants.push(["Title", this.state.carInfo.titletype]);
        tableConstants.push(["Vin/Licence Plate", this.state.carInfo.vinlic]);
        tableConstants.push(["# of Accidents", this.state.carInfo.numAccident]);
        tableConstants.push(["# of Previous Owners", this.state.carInfo.numOwner]);
        tableConstants.push(["Mileage", this.state.carInfo.mileage]);

        let disclosures = (
            <div style={{float:"right"}}>
            <ul>
                {this.state.disclosures.map(dis=>{
                    return (<li>{dis}</li>);
                })}
            </ul>
            </div>
        );
        tableConstants.push(["Disclosures", disclosures])

        return tableConstants
    }

    render() {


       

        


        return (

            <Accordion>

                <AccordionSummary
                    expandIcon={< ExpandMoreIcon />}
                    aria-label="Confirm Info"
                    aria-controls="additional-actions3-content"
                    id="additional-actions3-header">
                    <FormControlLabel
                        aria-label="Confirm Info"
                        onClick={(event) => event.stopPropagation()}
                        onChange={this.handleChange}
                        checked={this.state.authorized}
                        disabled={this.state.authorized}
                        control={< Checkbox />}
                        label="Confirm Info"/>
                </AccordionSummary>
                <AccordionDetails>
                    <ConfirmKnowledge contents={this.getTableConstants()}/>
                </AccordionDetails>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Confirm Seller Details?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Confirm this information before inviting people to make the purchase. Once you
                            confirm this information, you will not be able to edit it.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={this.handleAgree} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>

            </Accordion>
        );
    }
}

export default withFirebase(Acknowledge);
import {Box, Button, Grid, Typography, TextField, List, ListItem, ListItemText, LinearProgress} from '@material-ui/core';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {withFirebaseAuthSecure} from '../Firebase';
import HeadBar from '../General/HeadBar/index.js';
import shortid from "shortid";
import * as ROUTES from '../../constants/routes'

import SellerFlowContainer from './SellerFlowContainer';
/*
Seller In Between Page
*/


class SellerFlowWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.transactionId = this.props.match.params.token;
        
        this.componentDidMount = this.componentDidMount.bind(this);
        this.state = {
            isLoading: false
        }
    }
    
    componentDidMount(){
        
        //make sure transaction id and claimed and if not redirect, add to history and render the sellerflow
        this.props.firebase.claimed(this.transactionId).once("value", snapshot=>{
            
            const val = snapshot.val();
            if (val === this.props.authUser.uid || !val) {

                if (!val) {
                    this.props.firebase.claimed(this.transactionId).set(this.props.authUser.uid);
                }
                
                let sellHistoryObj = {};
                sellHistoryObj[this.transactionId] = new Date();
                this.props.firebase.sellHistory(this.props.authUser.uid).update(sellHistoryObj);

                this.setState({
                    isLoading: false,
                });
            } else {
                this.props.history.push(ROUTES.SELLER_TOKEN.replace(':token',this.transactionId))
            }

        });
    }

    render() {

        if(this.state.isLoading){
            return (<LinearProgress/>);
        }
        return (

            <SellerFlowContainer token={this.props.match.params.token}/>
        );  
    }
}

export default withRouter(withFirebaseAuthSecure(SellerFlowWrapper))

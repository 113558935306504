import React from 'react'

import {withRouter} from "react-router-dom"
import {withFirebaseAuth} from '../../../Firebase';

import {Button, Typography, Box } from '@material-ui/core'
import SnackbarAlert from '../../../General/SnackbarAlert';
import authRequest from '../../../General/AuthRequest';


class LinkAccount extends React.Component{
    constructor(props){
        super(props)
        this.transactionId = this.props.token;

        this.state = {
            isLoading: true,
            link: '',
            alertOpen: false,
            alertMessage: '',
            chargesEnabled: false,
            detailsSubmitted: false,
            linkStarted: false
        }

        this.populateLink = this.populateLink.bind(this);
        this.goToLink = this.goToLink.bind(this);
        this.manageAccountStatus = this.manageAccountStatus.bind(this);
    }

    componentDidMount(){
        this.populateLink();
        this.manageAccountStatus();
    }

    async manageAccountStatus(){
        const resJson = await authRequest(`/api/selleraccount`, "GET", {}, this.props.authUser.idToken);

        console.log(resJson)
        let chargesEnabled = !!resJson['charges_enabled'];
        let detailsSubmitted = !!resJson['details_submitted'];

        this.setState({ 
            chargesEnabled,
            detailsSubmitted,
            linkStarted: !!resJson.id
        })

        this.props.onChange(chargesEnabled && detailsSubmitted);
    }

    async populateLink(){
        try{
            const resJson = await authRequest(`/api/sellerlink/${this.props.token}`, "GET", {}, this.props.authUser.idToken);
            // const result = await fetch(`/api/sellerlink/${this.props.authUser.uid}/${this.props.token}`)
            // const resJson = await result.json();
            if(!resJson.url){throw new Error("Could Not Get URL")}
            
            this.setState({
                link: resJson.url,
                isLoading: false
            })
        }catch(e){
            console.log(e);
            this.setState({
                alertOpen: true,
                alertMessage: "Error Connecting to Stripe"
            })
        }
        
    }

    goToLink(){
        window.location = this.state.link;
    }

    getButtonMessage(){
        if(this.state.chargesEnabled && this.state.detailsSubmitted){
            return "View Linked Account"
        }
        if(this.state.chargesEnabled && !this.state.detailsSubmitted){
            return "Finish"
        }
        if(this.state.linkStarted){
            return "View Progress"
        }
        return "Link"
    }

    render(){
        
        return (
            <React.Fragment>
                <Button disabled={this.state.isLoading} onClick={this.goToLink}> {this.getButtonMessage()} </Button>
                {(this.state.linkStarted && !this.state.chargesEnabled) && 
                    <Box display="flex" alignItems="center" justifyContent="center">
                            <Typography variant="caption" display="block" gutterBottom>(Pending)</Typography>
                    </Box>}
                <SnackbarAlert open={this.state.alertOpen} message={this.state.alertMessage} onClose={()=>{this.setState({alertOpen:false})}}/>
            </React.Fragment>
        );
    }


}

export default withRouter(withFirebaseAuth(LinkAccount));
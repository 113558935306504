import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { withFirebaseAuth } from '../../../../Firebase';






class Authorize extends React.Component{
  constructor(props){
    super(props);
    this.transactionId = this.props.token;

    this.state = {authorized:false}
    this.props.firebase.authorizedPayment(this.transactionId, this.props.authUser.uid).once("value").then(snapshot =>{
      const sval = snapshot.val();
      this.setState({authorized: sval});
      this.props.onChange(sval);
    });

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event){
    this.setState({authorized: event.target.checked});
    this.props.firebase.authorizedPayment(this.transactionId, this.props.authUser.uid).set(event.target.checked);
    this.props.onChange(event.target.checked);
  }

  render(){
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Authorize Payment"
          aria-controls="additional-actions3-content"
          id="additional-actions3-header"
        >
          <FormControlLabel
            aria-label="Authorize Payment"
            onClick={(event) => event.stopPropagation()}
            onChange={this.handleChange}
            checked={this.state.authorized || this.props.alreadyPayed}
            control={<Checkbox />}
            disabled={this.props.alreadyPayed}
            label="Authorize Payment"
          />
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="textSecondary">
            <Box fontStyle="italic">
              By clicking the "Authorize Payment" checkbox and
              "Save & Next" below, I authorize my payment to be
              processed as an electronic funds transfer or draft
              drawn from the checking or savings account shown
              above ("Deposit Account") by Crow, Inc., which I
              maintain with the depository institution with the
              referenced ABA routing number (the "Bank").
            </Box>
            <Box fontStyle="italic" mt={2}>
              I also authorize the Bank to accept and pay these
              debits when charged to my Deposit Account. I
              certify that I am individually authorized to make
              payments and withdrawals from the Deposit Account.
              I agree that these debit entries comply with
              applicable law. I agree to print or save a copy of
              this document for my records.
            </Box>
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withFirebaseAuth(Authorize);

import React, { Children } from 'react'
import firebase from 'firebase';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as ROUTES from '../../constants/routes';

// const config = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: '1:673821673797:web:3cb4ce339cb7340c92962c',
//   measurementId: "G-DKZLSDZL0S"
// };
const config = {
  "projectId": "crow2-f7ce7",
  "appId": "1:673821673797:web:3cb4ce339cb7340c92962c",
  "databaseURL": "https://crow2-f7ce7.firebaseio.com",
  "storageBucket": "crow2-f7ce7.appspot.com",
  "apiKey": "AIzaSyDysZd4utI72l8vBWtGIL0DrX9V8yQEmJU",
  "authDomain": "crow2-f7ce7.firebaseapp.com",
  "messagingSenderId": "673821673797",
  "measurementId": "G-DKZLSDZL0S"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    this.auth_ = app.auth;

    /* Social Sign In Method Provider */

    this.analytics = app.analytics();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();




  }

  // *** Auth API ***
  getSignInComponent = (handleSuccess) => {

    let uiConfig = {

      signInOptions: [
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      signInFlow: 'popup',
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: (val)=>{

          this.user(val.user.uid).set({
            phoneNumber: val.user.phoneNumber,
            email: val.user.email
          })
          handleSuccess();
          return true;
        }
      }

    }
    return (<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>);
  }
  // getEmailSignInComponent = () => {

  //   let uiConfig = {
  //     signInOptions: [
  //       firebase.auth.EmailAuthProvider.PROVIDER_ID
  //     ],
  //     callbacks: {
  //       // Avoid redirects after sign-in.
  //       signInSuccessWithAuthResult: (val)=>{

  //         this.user(val.user.uid).set({
  //           email: val.user.email,
  //         })
  //         return true;
  //       }
  //     },
  //     signInSuccessUrl: ROUTES.CHECKOUT
  //   }
  //   return (<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>);
  // }


  doSignOut = () => this.auth.signOut();


  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles

            authUser.getIdToken(true).then(idToken => {

            // merge auth and db user
              authUser = {
                uid: authUser.uid,
                phone: authUser.phoneNumber,
                providerData: authUser.providerData,
                idToken,
                ...dbUser,
              };
              next(authUser);

            });
            
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  price = (transactionId) => this.db.ref(`transactions/${transactionId}/price`);

  buyerReady = (transactionId) => this.db.ref(`transactions/${transactionId}/buyerReady`);

  reviewedPayment = (transactionId, userId) => this.db.ref(`transactions/${transactionId}/buyers/${userId}/confirmations/reviewedPayment`);

  authorizedPayment = (transactionId, userId) => this.db.ref(`transactions/${transactionId}/buyers/${userId}/confirmations/authorizedPayment`);

  disclosureAgree = (transactionId, userId) => this.db.ref(`transactions/${transactionId}/buyers/${userId}/confirmations/disclosures`);

  bankInfo = (transactionId, userId) => this.db.ref(`transactions/${transactionId}/buyers/${userId}/bank/bankInfo`);

  buyHistory = (userId) => this.db.ref(`users/${userId}/buyHistory`);

  userBasicInfo = (transactionId, userId) => this.db.ref(`transactions/${transactionId}/buyers/${userId}/basicInfo`);

  activeStep = (transactionId, userId) => this.db.ref(`transactions/${transactionId}/buyers/${userId}/activeStep`);

  referrals = (transactionId) => this.db.ref(`transactions/${transactionId}/referrals`);

  sellerReferralStatus = (transactionId) => this.db.ref(`transactions/${transactionId}/referrals/seller/status`);

  sellHistory = (userId) => this.db.ref(`users/${userId}/sellHistory`);

  sellerBasicInfo = (transactionId) => this.db.ref(`transactions/${transactionId}/sellerInfo`);

  sellerState = (transactionId) => this.db.ref(`transactions/${transactionId}/sellerInfo/state`);

  claimed = (transactionId) => this.db.ref(`transactions/${transactionId}/claimed`);

  sellerActiveStep = (transactionId) => this.db.ref(`transactions/${transactionId}/sellerActiveStep`);

  sellerCarInfo = (transactionId) => this.db.ref(`transactions/${transactionId}/carInfo`);

  disclosures = (transactionId) => this.db.ref(`transactions/${transactionId}/disclosures`);

  buyerStatus = (transactionId) => this.db.ref(`transactions/${transactionId}/buyerstatus`);

  buyerClaimedUid = (transactionId) => this.db.ref(`transactions/${transactionId}/buyerClaimed/uid`);
  
  signatures = (transactionId) => this.db.ref(`transactions/${transactionId}/signatures`);

  title = (transactionId) => this.db.ref(`transactions/${transactionId}/title`);

  titleStatus = (transactionId) => this.db.ref(`transactions/${transactionId}/title/status`);
  
  titleDocument = (transactionId) => this.db.ref(`transactions/${transactionId}/title/files`);

  titleNames = (transactionId) => this.db.ref(`transactions/${transactionId}/title/filenames`);

  titleDate = (transactionId) => this.db.ref(`transactions/${transactionId}/title/date`);

  paymentTransferred = (transactionId) => this.db.ref(`transactions/${transactionId}/payments/transferred`);
  
  sellerCustomMessage = (transactionId) => this.db.ref(`transactions/${transactionId}/sellerCustomMessage`); 
}

export default Firebase;


import { Button, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import { withFirebaseAuth } from '../../../Firebase';
import DisputeModal from '../../../General/DisputeModal'
import PaymentStatus from './PaymentStatus';
import Steps from './Steps';





class NextSteps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerState: 'AL',
      disputeModalActivate: false,
      isLoading: true,
    };

    this.loadState = this.loadState.bind(this);
    this.handleDisputeModalChange = this
          .handleDisputeModalChange
          .bind(this);
  }

  

  componentDidMount() {
    this.loadState();
  }

  async loadState(){
    const stateSnapshot = await this.props.firebase.sellerState(this.props.token).once('value');
    this.setState({
      sellerState: stateSnapshot.val() || 'AL',
      isLoading: false
    });
  }

  handleDisputeModalChange() {
    this.setState({disputeModalActivate: !this.state.disputeModalActivate});
  }

 
  render() {
    
    
    const stylesheet = {
      paper:{
        minHeight: '50vh'
      }, 
      pdfBox:{
        height: '25vh'
      },
      
            numberAvatar: {
              backgroundColor: '#360D73'
            }

    }


    return (
      <div>
    
            <Paper variant="outlined" style={stylesheet.paper}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Grid item xs={12} md={8}>
                  <Box m={4}>
                    <Grid container direction="column" justify="center" alignItems="flex-start" spacing={0}>
                      <Grid item xs={12}>
                        <Typography variant="overline"> <Box display="inline" fontWeight="fontWeightBold" fontSize={24}>Next Steps</Box> </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="overline" noWrap> <Box display="inline" fontSize={12}>5 min</Box></Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">
                        In these steps, we will help you with signing over the legal ownership of the vehicle and access the funds that the buyer has paid you. If there are any issues, please launch a dispute. 
                          <Box display="inline">
                            <IconButton onClick={this.handleDisputeModalChange}>
                              <LaunchIcon fontSize="small" />
                            </IconButton>  
                          </Box>                           
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider/>
                </Grid>

                <Grid item xs={8}>
                <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={2}>
              
                <Steps token={this.props.token} sellerState={this.state.sellerState} />
                <Divider/>
                <PaymentStatus token={this.props.token}/>
                </Grid>
                </Grid>
                <Grid item xs={12}>
                <Box m={4}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item xs={4}>
                        <Button fullWidth onClick={this.props.backHandler}>
                          Back
                        </Button>
                    </Grid>
                </Grid>

                </Box>

                </Grid>

              </Grid>
              <DisputeModal open={this.state.disputeModalActivate} setOpen={this.handleDisputeModalChange} isBuyer/>
              


            </Paper>
          
      </div>
    );
  }
}

export default withFirebaseAuth(NextSteps);

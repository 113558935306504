import React from 'react';
import LinearProgres from '@material-ui/core/LinearProgress';
import {Redirect} from 'react-router-dom';

class Redirect404 extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: true, 
            buyerRedirect: false,
            sellerRedirect: false,
        }
    }
    componentDidMount(){
        const currentURL = window.location.href || '';

        if(currentURL.includes('seller')){
            this.setState({sellerRedirect: true, isLoading: false})
        }else if(currentURL.includes('checkout')){
            this.setState({buyerRedirect: true, isLoading: false})
        }else{
            this.setState({isLoading: false});
        }
    }
    render(){
        if(this.state.isLoading){
            return <LinearProgres/>
        }
        if(this.state.buyerRedirect){
            return <Redirect to='/checkout'/>
        }
        if(this.state.sellerRedirect){
            return <Redirect to='/seller'/>
        }
        return <Redirect to='/'/> 
    }
}
export default Redirect404;
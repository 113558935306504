import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import heeboFont from "./heebo.ttf";



Font.register({ family: 'Heebo', src: heeboFont});
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    padding: 40
  },
  mainview:{
    
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  header:{
      marginTop: 25,
      textAlign: "center",
      fontSize: 30,
      fontFamily: 'Heebo'
  },
  crowLogo:{
      width: 100,
  },
  sectionTitle:{
    marginTop:30,
    fontSize:20,
    fontWeight:"bold",
    fontFamily: 'Heebo'
  },
  sectionContent:{
      marginTop:10,
      fontSize:16,
      fontFamily: 'Heebo'
  },
  signatureTop:{
    marginTop:50
  },
  signature:{
      marginTop:10
  },
  signatureImage:{
    width:100
  }

});

// Create Document Component
const MyDocument = (props) => (
    
  <Document>
    <Page size="A4" style={styles.page}>
      
      <View style={styles.mainview}>
      
        <Image
                style={styles.crowLogo}
                src={'/logo_black_tire.png'}
        />
       
        
        <Text style={styles.header}>Bill Of Sale</Text>

        <Text style={styles.sectionTitle}>1. Location of Sale</Text>
        <Text style={styles.sectionContent}>This bill of sale was created on {props.data.date} in the State of {props.data.state} in the Zip code of {props.data.zip}</Text>


        <Text style={styles.sectionTitle}>2. The Parties</Text>
        <Text style={styles.sectionContent}>The Buyer, {props.data.buyername}, with a mailing address of {props.data.buyeraddress} (Hereinafter the "Buyer") and </Text>       
        <Text style={styles.sectionContent}>The Seller, {props.data.sellername}, with a mailing address of {props.data.selleraddress} (Hereinafter the "Seller") hereby agree as follows:</Text>


        <Text style={styles.sectionTitle}>3. Payment</Text>
        <Text style={styles.sectionContent}>The Buyer agrees to pay ${props.data.price} (US Dollars) to the Seller.</Text>

        <Text style={styles.sectionTitle}>4. Vehicle Description</Text>
        <Text style={styles.sectionContent}>Make Model Year: {props.data.carname}</Text>
        <Text style={styles.sectionContent}>Mileage: {props.data.mileage}</Text>
        <Text style={styles.sectionContent}>Vehicle Identification Number (VIN) or Licence Plate:  {props.data.vinlic}</Text>
        <Text style={styles.sectionContent}>Disclosures Regarding Car’s Condition: {props.data.disclosures}</Text>
        <Text style={styles.sectionContent}>Taxes. All municipal, county, and State taxes in relation to the purchase of the motor vehicle, including sales taxes, are not included in the purchase price.</Text>

        <Text style={styles.sectionTitle}>5. Odometer Disclosure Statement</Text>
        <Text style={styles.sectionContent}>FEDERAL and STATE LAW requires that you state the mileage in connection with the transfer of ownership. Failure to complete or providing a false statement may result in fines and/or imprisonment. I/We, {props.data.sellername} certify to the best of my knowledge that the odometer reading of {props.data.mileage} Miles.  The actual mileage of the vehicle is accurate.</Text>




        <Text style={styles.signatureTop}>Buyer Name: {props.data.buyername} </Text>
        <Text>Buyer Signature:</Text>
        {(props.buyerSignature && props.buyerSignature!=="NONE") && <Image style={styles.signatureImage} src={props.buyerSignature}/>}

        <Text style={styles.signatureTop}>Seller Name: {props.data.sellername} </Text>
        <Text style={styles.signature}>Seller Signature: </Text>

        
        {(props.sellerSignature && props.sellerSignature !== "NONE") && <Image style={styles.signatureImage} src={props.sellerSignature}/>}

        
        
      
      


        
      </View>
    </Page>
  </Document>
  
);

export default MyDocument;
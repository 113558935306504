import React from 'react'
import { withFirebase } from '../../../../Firebase';
import Button from './Button';
import {withRouter} from 'react-router-dom'
class signInWithGoogleButton extends React.Component{
    constructor(props){
        super(props);
        this.signInWithGoogle = this.signInWithGoogle.bind(this);
    }

    signInWithGoogle(){
        this.props.firebase.auth.signInWithPopup(this.props.firebase.googleProvider).then((result)=> {
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            this.props.history.push(this.props.nexturl);
            // ...
          }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
    }

    render(){
        return (
            <Button onClick={this.signInWithGoogle} wide>
                            Sign In With Google</Button>
        );
    }


}

export default withRouter(withFirebase(signInWithGoogleButton));
import React from 'react';
import App from './src/App.js'



const Landing = () => (
  <App/>
);

export default Landing;

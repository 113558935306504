import {Button, FormControl, TextField, InputAdornment} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import VALIDATOR from '../../../General/Validator';
import Constants from '../../Constants';
import {withFirebase} from '../../../Firebase'

import {Title, Page, Content} from '../../../General/Page'
import FaqSection from '../../../General/FaqSection'

class BasicCarInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            make: 'Abarth',
            model: '',
            year: '2020',
            mileage: '',
            vinlic: '',
            titletype: 'Clean',
            numOwner: '',
            numAccident: '',
            errors: {}
        };

        //fields that will be validated
        this.validateFields = new Set([
            'make',
            'model',
            'year',
            'mileage',
            'vinlic',
            'titletype',
            'numOwner',
            'numAccident'
        ])

        this.transactionId = this.props.token;

        this.handleChange = this
            .handleChange
            .bind(this);
        this.fbLoad = this
            .fbLoad
            .bind(this);
        this.fbSave = this
            .fbSave
            .bind(this);
        this.nextPage = this
            .nextPage
            .bind(this);
        this.validateInput = this
            .validateInput
            .bind(this);

    }

    //returns true if all inputs are valid, displays error messages if not
    validateInput() {

        let foundError = false;
        let errors = {};
        for (let field of this.validateFields) {
            const fieldUse = VALIDATOR.hasOwnProperty(field)
                ? field
                : "required";
            if (!VALIDATOR[fieldUse](this.state[field])) {
                foundError = true;
                errors[field] = true;
            } else {
                errors[field] = false
            }
        }
        this.setState({errors: errors})
        return !foundError;
    }

    async fbLoad() {
        return this
            .props
            .firebase
            .sellerCarInfo(this.transactionId)
            .once("value", snapshot => {
                if (snapshot.exists()) {
                    this.setState(snapshot.val())
                }
            });
    }

    //if save Errors, then save all of the errors in fb
    async fbSave() {
        let updateObj = {};
        for (let field of this.validateFields) {
            updateObj[field] = this.state[field];
        }
        return this
            .props
            .firebase
            .sellerCarInfo(this.transactionId)
            .update(updateObj);
    }

    componentWillMount() {
        this.fbLoad();

    }
    handleChange(e) {
        let newObj = {};
        newObj[e.target.name] = e.target.value;
        this.setState(newObj);
    }

    //wrapper function for going to the next page
    async nextPage(e) {
        e.preventDefault();
        const isValid = this.validateInput();
        await this.fbSave();
        if (isValid) {
            this
                .props
                .nextHandler(e);
        }

    }

    render() {
        const states = Constants.states;
        const carMakes = Constants.carMakes;
        const titleTypes = [
            'Clean',
            'Clear',
            'Lien',
            'Salvage',
            'Rebuilt',
            'Lemon',
            'Other'
        ];
        const year = (new Date()).getFullYear();
        const years = Array.from(new Array(20), (val, index) => year - index);

        const stylesheet = {
            form: {
                '& .MuiTextField-root': {
                    margin: '4px'
                }
            }
        }
        return (


            <Page>
                <Title
                    title="Basic Vehicle Information"
                    minutes="2"
                    description="We need this information to help you begin your car sale purchase process. All your information is secure using bank-grade security.">
                    <FaqSection section="bvi"/>

                </Title>

                <Grid item xs={12}>
                    <Box m={2}>
                        <form className={stylesheet.form} noValidate autoComplete="off" >
                            <FormControl
                            style={{minWidth:"500px"}}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="stretch"
                                    spacing={4}>
                                    
                                    <Grid item xs={12}>
                                        <Typography variant="overline" align="left">
                                            Basic Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                            spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    select
                                                    label="Make"
                                                    value={this.state.make}
                                                    name="make"
                                                    error={this.state.errors.make}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    SelectProps={{
                                                    native: true
                                                }}>
                                                    {carMakes.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    id="standard-required"
                                                    label="Model"
                                                    value={this.state.model}
                                                    name="model"
                                                    error={this.state.errors.model}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                    shrink: true
                                                }}
                                                    fullWidth/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                            spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    select
                                                    label="Year"
                                                    value={this.state.year}
                                                    name="year"
                                                    error={this.state.errors.year}
                                                    onChange={this.handleChange}
                                                    value={this.state.yearSelect}
                                                    onChange={this.handleYearChange}
                                                    fullWidth
                                                    SelectProps={{
                                                    native: true
                                                }}>
                                                    {years.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    id="standard-required"
                                                    value={this.state.mileage}
                                                    name="mileage"
                                                    error={this.state.errors.mileage}
                                                    onChange={this.handleChange}
                                                    label="Mileage"
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">miles</InputAdornment>,
                                                    }}
                                                    InputLabelProps={{
                                                    shrink: true,

                                                }}
                                                    fullWidth/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <TextField
                                            required
                                            value={this.state.vinlic}
                                            name="vinlic"
                                            error={this.state.errors.vinlic}
                                            onChange={this.handleChange}
                                            id="standard-required"
                                            label="VIN/License Plate"
                                            InputLabelProps={{
                                            shrink: true
                                        }}
                                            fullWidth={true}/>

                                    </Grid>

                                </Grid>
                            </FormControl>
                        </form>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box m={2}>
                        <form className={stylesheet.form} noValidate autoComplete="off">
                            <FormControl style={{minWidth:"500px"}}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="stretch"
                                    spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant="overline" align="left">
                                            Vehicle History
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                            spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    label="Title Type"
                                                    value={this.state.titletype}
                                                    name="titletype"
                                                    error={this.state.errors.titletype}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    SelectProps={{
                                                    native: true
                                                }}>
                                                    {titleTypes.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                            spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    id="standard-required"
                                                    label="# of Previous Owners"
                                                    type="number"
                                                    value={this.state.numOwner}
                                                    name="numOwner"
                                                    error={this.state.errors.numOwner}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                    shrink: true
                                                }}
                                                    fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    id="standard-required"
                                                    value={this.state.numAccident}
                                                    name="numAccident"
                                                    error={this.state.errors.numAccident}
                                                    onChange={this.handleChange}
                                                    label="# of Major Accidents"
                                                    type="number"
                                                    InputLabelProps={{
                                                    shrink: true
                                                }}
                                                    fullWidth/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box m={4}>
                                            <Button variant="contained" color="primary" fullWidth onClick={this.nextPage}>
                                                Next
                                            </Button>
                                        </Box>
                                    </Grid>

                                </Grid>


                            </FormControl>
                        </form>
                    </Box>
                </Grid>
            </Page>


        );
    }
}

export default withFirebase(BasicCarInfo);

import {Box, LinearProgress} from '@material-ui/core';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {withFirebaseAuthSecure} from '../../../../Firebase';
import shortid from "shortid";
import * as ROUTES from '../../../../../constants/routes';
import ViewHistory from './ViewHistory'
import classNames from 'classnames';
import Alert from '@material-ui/lab/Alert';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Typography from '@material-ui/core/Typography';


/*
Seller In Between Page
*/

class Checkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refCode: this.props.token === "FORGOT"
                ? ""
                : this.props.token
        };

        this.submitRefCode = this.submitRefCode.bind(this);
    }

    submitRefCode(e){
      e.preventDefault();
      this.props.history.push(`/checkout/${this.state.refCode}`)
    }

    render() {
        const {
            className,
            topOuterDivider,
            bottomOuterDivider,
            topDivider,
            bottomDivider,
            hasBgColor,
            invertColor,
            ...props
        } = this.props;

        const outerClasses = classNames('signin section', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);

        const innerClasses = classNames('signin-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');
        const inputClasses = classNames('form-input');
        const sectionHeader = {
            title: 'My Sales'
        };

        return (
            <section {...props} className={outerClasses}>
                <div className="container">
                    <div className={innerClasses}>
                        <SectionHeader tag="h1" data={sectionHeader} className="center-content"/>
                        <div className="tiles-wrap">

                            <div className="tiles-item">
                                <Box>

                                    <Box m={2}>
                                        <div className="tiles-item-inner">
                                            <form>
                                                <fieldset>
                                                    <div className="mt-24 mb-32">
                                                        <Button id="new-sale-button" color="primary" wide
                                                        onClick={(e)=>{
                                                          this.props.history.push('/getstarted')
                                                        }}
                                                        >New Sale</Button>
                                                    </div>
                                                    <div className="mt-24 mb-32">
                                                        <Typography variant="overline">
                                                            <Box m="auto" align="center">
                                                                or
                                                            </Box>
                                                        </Typography>
                                                    </div>
                                                    <div className="mb-12">
                                                        <Input
                                                            name="code"
                                                            label="Sale Code"
                                                            placeholder="123456"
                                                            onChange={(e) => {
                                                            this.setState({refCode: e.target.value})
                                                        }}
                                                            value={this.state.refCode}/>
                                                    </div>

                                                    <div className="mt-24 mb-32">
                                                        <Button id="sign-in-button" onClick={this.submitRefCode} color="light" wide>Enter Sale</Button>
                                                    </div>
                                                    <div className="mb-12">
                                                        {(this.props.token!=="FORGOT" && !!this.props.token) && <Alert severity="error">Invalid Sale Code</Alert>
}
                                                    </div>
                                                </fieldset>
                                            </form>
                                            <div className="signin-bottom has-top-divider">
                                                <div className="pt-32 text-xs center-content text-color-low">
                                                    <ViewHistory history={this.props.views}  isCheckout={true}/>

                                                </div>
                                            </div>

                                        </div>
                                    </Box>
                                </Box>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );

    }
}

export default withRouter(withFirebaseAuthSecure(Checkout))
import React from 'react';
import BuyerReferral from '../components/sections/BuyerReferral';

class BuyerReferralPage extends React.Component {
  render() {
    return (
      <BuyerReferral id={this.props.match.params.id}  />
    );
  }
}

export default BuyerReferralPage;
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import {withFirebaseAuthSecure} from '../../Firebase';
import HeadBar from '../../General/HeadBar/index.js';
import CheckoutMenu from '../../General/Stepper.js';
import BasicDocs from './CarInfo-2';
import BuyerInfo from './BuyerInfo-1';
import CarInfo from '../CarBanner';
import NextSteps from './NextSteps-4';
import PaymentDetails from './PaymentDetails-3';

import Footer from '../../Landing/src/components/layout/FooterLight'
import authRequest from '../../General/AuthRequest';

class CheckoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            signedIn: !!this.props.authUser,
            isLoading: true
        };
        this.setActiveStep = this
            .setActiveStep
            .bind(this);
        this.getNextStep = this
            .getNextStep
            .bind(this);
        this.getPrevStep = this
            .getPrevStep
            .bind(this);
        this.handleSignedIn = this
            .handleSignedIn
            .bind(this);
        this.getStepContent = this
            .getStepContent
            .bind(this);
        this.componentDidMount = this
            .componentDidMount
            .bind(this);

    }
    componentDidMount() {
        this
            .props
            .firebase
            .activeStep(this.props.token, this.props.authUser.uid)
            .once("value", snapshot => {
                if (snapshot.exists()) {
                    this.setState({
                        isLoading: false,
                        activeStep: snapshot.val()
                    })
                } else {
                    this.setState({activeStep: 0, isLoading: false})
                }
                this.updateTracking(snapshot.val() || 0);

            });
    }

    async updateTracking(step) {
        //update the status by phone number
       
        // const resp = await fetch('/api/updatebuyerstatus', {
        //     method: 'POST',
        //     body: JSON.stringify({uid: this.props.authUser.uid, transactionId: this.props.token, activeStep: step || 0, otherInfo: "none"})
        // });
        const respJSON = await authRequest('/api/updatebuyerstatus', 'POST', {transactionId: this.props.token, activeStep: step || 0, otherInfo: "none" }, this.props.authUser.idToken);
    }

    setActiveStep(step) {
        this
            .props
            .firebase
            .activeStep(this.props.token, this.props.authUser.uid)
            .set(step);
        this.setState({activeStep: step});
        this.props.firebase.analytics.logEvent(`buyer_step_${step}`)
        this.updateTracking(step);

    }
    handleSignedIn() {
        alert(this.state.signedIn);
    }

    getStepContent(step) {
        switch (step) {
            case 0:
                return (<BuyerInfo token={this.props.token} nextHandler={this.getNextStep}/>);
            case 1:
                return (<BasicDocs
                    token={this.props.token}
                    nextHandler={this.getNextStep}
                    backHandler={this.getPrevStep}/>);
            case 2:
                return (<PaymentDetails
                    token={this.props.token}
                    nextHandler={this.getNextStep}
                    backHandler={this.getPrevStep}/>);
            case 3:
                return (<NextSteps
                    token={this.props.token}
                    nextHandler={this.getNextStep}
                    backHandler={this.getPrevStep}/>);
            default:
                return (<NextSteps
                    token={this.props.token}
                    nextHandler={this.getNextStep}
                    backHandler={this.getPrevStep}/>);
        }
    }
    getNextStep() {
        this.setActiveStep(this.state.activeStep + 1);

    }
    getPrevStep() {
        this.setActiveStep(this.state.activeStep - 1);
    }

    render() {

        if (this.state.isLoading) {
            return (<LinearProgress/>); //could be optimized by doing read in index.js
        }

        return (
            <React.Fragment>
                <HeadBar/>

                <Grid container direction="row" justify="center">

                    <Grid item xs={12} md={8} style={{marginTop:"50px"}}>
                        <CarInfo token={this.props.token}/>
                    </Grid>
                    <Grid item xs={12} md={9} style={{marginTop:"35px"}}>
                        <CheckoutMenu
                            activeStep={this.state.activeStep}
                            setActiveStep={this.setActiveStep}
                            steps={['Your Info', 'Car Info', 'Payment Details', 'Next Steps']}/>
                    </Grid>
                    <Grid item xs={12} md={8} style={{marginTop:"30px"}}>
                        {this.getStepContent(this.state.activeStep)}
                    </Grid>
                </Grid>

                <Footer/>
            </React.Fragment>

        );
    }
}

export default withFirebaseAuthSecure(CheckoutPage);
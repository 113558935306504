import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link, withRouter } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import { Grid } from '@material-ui/core';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class GetStartedPage extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Are you looking to buy or sell?',
    };
    
    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h3" data={sectionHeader} className="center-content" />
            
                  <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                        
                              <Image
                                src={require('./../../assets/images/buyingcar.png')}
                                alt="Buying a Car"
                                width={528}
                                height={396} />
                        </Grid>
                        <Grid item xs={12}>
                        <div className="mt-24 mb-32">
                        <Button color="primary" wide onClick={()=>{this.props.history.push("/getstarted/buyer")}}>I'm Buying a Car!</Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                          
                              <Image
                                src={require('./../../assets/images/sellingcar.png')}
                                alt="Selling a Car"
                                width={528}
                                height={396} />
                        </Grid>
                        <Grid item xs={12}>
                        <div className="mt-24 mb-32">
                        <Button color="light" wide onClick={()=>{this.props.history.push("/getstarted/seller")}}>I'm Selling a Car!</Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className="signin-bottom has-top-divider">
                    <div className="pt-32 text-xs center-content text-color-low">
                      Already started? <Link to="/login/" className="func-link">Login</Link>
                    </div>
                  </div>
                
          </div>
        </div>
      </section>
    );
  }
}

GetStartedPage.propTypes = propTypes;
GetStartedPage.defaultProps = defaultProps;

export default withRouter(GetStartedPage);
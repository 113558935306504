import React from 'react'
import Dropzone from 'react-dropzone'
import {withFirebase} from '../../../../Firebase';
import StyledDropzone from './StyledDropzone';
import firebase from 'firebase';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { List, ListItem, ListItemIcon, ListItemText, IconButton, ListItemSecondaryAction } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TITLE_STATUS from './constants';

class UploadDialogue extends React.Component{
    constructor(props){
        super(props);
        this.acceptFiles = this.acceptFiles.bind(this);
        this.state = {
            status: "LOADING",
            downloadedfiles: [],//blobs
            myfiles: [],//file objects
            date: null,
            filenames:[],//strings
            changed: false
        }
        this.load = this.load.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.acceptFiles = this.acceptFiles.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.closeWithConfirm = this.closeWithConfirm.bind(this);
        this.setFiles = this.setFiles.bind(this);
    
    }

    componentDidMount(){
        this.load();
    }
    blobToFile(theBlob, fileName){
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }
    async getFile(file){
        const res = await fetch(file);

        const decodedFile = await res.blob();
        
        return decodedFile;
        
    }
    async setFiles(files, filenames){
        let scrubbedFiles = await Promise.all(files.map(this.getFile));
        this.setState({myfiles: this.mergeFiles(filenames, scrubbedFiles)});
    }
    async load(){
        this.props.firebase.title(this.props.token).once('value', (titleSnapshot) => {
            if(titleSnapshot.val()){
                const {date, filenames, files, status} = titleSnapshot.val();
                this.setState({status: status || TITLE_STATUS.notuploaded});
                if(files && filenames){
                    this.setFiles(files, filenames);
                    this.props.onChange(true);
                }
                if(date && date.seconds && date.nanoseconds){
                    const dateObj = new firebase.firestore.Timestamp(date.seconds, date.nanoseconds);
                    this.setState({date: dateObj.toDate().toString()})
                }
            }
            
        });
        
        

    }

    toBase64(file){
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    removeFile(index){
        let oldMyFiles = this.state.myfiles;
        oldMyFiles.splice(index, 1);
        this.setState({myfiles: oldMyFiles, changed: true});
    }

    acceptFiles(acceptedFiles){
        let newMyFiles = this.state.myfiles;
        acceptedFiles.forEach(file=> newMyFiles.push(file));
        this.setState({myfiles: newMyFiles, changed:true});
        
    }
    async onUpload(){
        let updateFiles = [];
        let filenames = [];
        for(let file of this.state.myfiles){
            console.log(typeof(file));
            const outcome = await this.toBase64(file);
            updateFiles.push(outcome);
            filenames.push(file.name);
        }

        let updateObj = {
            status: (filenames.length <=0) ? TITLE_STATUS.notuploaded : TITLE_STATUS.unseen,
            files: updateFiles,
            filenames: filenames
        }
        if(this.state.changed && filenames.length > 0){
            updateObj.date = firebase.firestore.Timestamp.fromDate(new Date());
        }
        if(filenames.length <= 0){
            
        }
         //TODO: Fairly buggy but this will technically work. If they change the documents timer resets, should have warning about resetting the timer 
        this.props.firebase.title(this.props.token).update(updateObj);
        this.props.onChange(filenames.length > 0);
        this.setState({changed: false});
        this.props.onClose();

    }
    
    mergeFiles(filenames, downloadedFiles){
        let newDownloadedFiles = [];
        for(let i = 0; i < downloadedFiles.length; i++){
            newDownloadedFiles.push(this.blobToFile(downloadedFiles[i], filenames[i]));
        }
        return newDownloadedFiles;
    }
    closeWithConfirm(){
        let confirm = true;
        if(this.state.changed){
            confirm = window.confirm("Are you sure you want to close this box? You have not uploaded the changes to these files.");
        }
        if(confirm){
            this.setState({changed: false});
            this.props.onClose();
        }
    }
    downloadBlob(blob, name) {
          // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
          const blobUrl = window.URL.createObjectURL(blob);
        
          // Create a link element
          const link = window.document.createElement("a");
        
          // Set link's href to point to the Blob URL
          link.href = blobUrl;
          link.download = name;
        
          // Append link to the body
          window.document.body.appendChild(link);
        
          // Dispatch click event on the link
          // This is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent('click', { 
              bubbles: true, 
              cancelable: true, 
              view: window 
            })
          );
        
          // Remove link from body
          window.document.body.removeChild(link);
        }
    downloadFileButtonAction(file){
        console.log(file);
        this.downloadBlob(file, file.name);
    }
    render(){
        
        const files = this.state.myfiles.map((file, index) => (
            
            <ListItem key={index} button onClick={() => this.downloadFileButtonAction(file)}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={file.name} secondary={file.size} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={(index) => {this.removeFile(index)}}>
                <HighlightOffIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          ));
        return(
                <Dialog onClose={this.closeWithConfirm} aria-labelledby="customized-dialog-title" open={this.props.open}>
        <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
          Upload Transferred Title
        </DialogTitle>
        <DialogContent dividers>
        
                <Typography variant="body2">
                    <Box m={2}>
                        Please upload a scanned copy of the front and back of your physical title. In the transfer ownership section (usually found on the back of the document), please sign it over to buyer.
                    </Box>
                </Typography>
                <StyledDropzone onDrop={this.acceptFiles}/>
                <Box m={2}>
                <Typography variant="overline">Uploaded Files</Typography>
                <List>
                    {files}
                </List>
                </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.onUpload} color="primary">
            Save
          </Button>
        </DialogActions>
                </Dialog>
                
           
        );
    }
}
export default withFirebase(UploadDialogue);
import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Accordion from '../elements/Accordion';
import AccordionItem from '../elements/AccordionItem';
import {withFirebase} from '../../../../Firebase'
const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class FaqSection extends React.Component {
  componentDidMount(){
    this.props.firebase.analytics.logEvent("viewed_faq")
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Frequently Asked Questions',
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <Accordion>
              <AccordionItem title="What does Crow do?" active>
              Crow allows two people that are interested in executing a car sale between each other to do so in a fast and secure way.
              </AccordionItem>
              <AccordionItem title="Why should I not do it by myself?">
              You can do it yourself, but private party car sales, especially between people who have met online, are prone to fraud and registration issues. Crow streamlines the process for a flat, one-time fee, and assists in resolving any disputes along the transaction.
              </AccordionItem>
              <AccordionItem title="Why should a buyer use Crow?">
              We check title ownership, vehicle history, and verify the seller before you buy a car, to ensure its not a fraudulent sale. We resolve any disputes during the sale process, and help streamline the title transfer paperwork. 
              </AccordionItem>
              <AccordionItem title="Why should a seller use Crow?">
              Our online payment is up to twice as fast as a check, and we are significantly more secure than a direct ACH/wire transfer. More importantly, we handle all the paperwork. 
              </AccordionItem>
              <AccordionItem title="Who pays the platform fee for Crow?">
              The buyer pays the small, one-time fee.  
              </AccordionItem>
              <AccordionItem title="Can I buy or sell multiple cars with Crow?">
                Absolutely.
              </AccordionItem>
              <AccordionItem title="Does Crow help me find buyers and sellers?">
                No, unfortunately, we do not cross list your car for privacy reasons.
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section>
    );
  }
}


export default withFirebase(FaqSection);
import React from 'react';

import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import {
    Button,
    List,
    ListItem,
    ListItemText,
    Card,
    CardMedia,
    CardContent,
    CardActionArea,
    CardActions,
    Tooltip
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FaqSection from '../../../General/FaqSection'
import {withFirebaseAuth} from '../../../Firebase';

class BasicDocs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            disclosures: [],
            isLoading: true,
            numAccident: '',
            numOwner: '',
            titleType: '',
            checked: {}
        };
        this.handleStateChange = this
            .handleStateChange
            .bind(this);
        this.setInformation = this
            .setInformation
            .bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);

        this.allAreChecked = this.allAreChecked.bind(this);

    }


    allAreChecked(){
      for(let key in this.state.checked){
        if(!this.state.checked[key]){
          return false;
        }
      }
      return true;
    }


    handleCheckChange(e, indexStr){
      var prevChecked = {...this.state.checked}
      prevChecked[indexStr] = e.target.checked;
      this.setState({checked: prevChecked});

      this.props.firebase.disclosureAgree(this.props.token, this.props.authUser.uid).set(prevChecked);
    }


    componentDidMount() {
        this.setInformation();
    }

    async setInformation() {
        const snapshots = await Promise.all([
            this
                .props
                .firebase
                .sellerCarInfo(this.props.token)
                .once("value"),
            this
                .props
                .firebase
                .disclosures(this.props.token)
                .once("value"),
            this
                .props
                .firebase
                .disclosureAgree(this.props.token, this.props.authUser.uid)
                .once("value")
        ])
        const sellerInfo = snapshots[0].val() || {};
        const disclosures = snapshots[1].val() || [];
        const checkedObj = snapshots[2].val() || {};

        for (let i = 0; i < disclosures.length; i++) {
            if(!checkedObj[i.toString()]){
                checkedObj[i.toString()] = false;
            }
            
        }
        this.setState({
            isLoading: false,
            disclosures: disclosures,
            numAccident: sellerInfo.numAccident,
            numOwner: sellerInfo.numOwner,
            titleType: sellerInfo.titletype,
            checked: checkedObj
        });

    }

    componentWillUnmount() {
        this._ismounted = false;
    }
    handleStateChange(e) {
        this.setState({stateSelect: e.target.value});
        e.preventDefault();
    }

    render() {

        const stylesheet = {
            paper: {
                minHeight: '50vh'
            },
            pdfBox: {
                height: '25vh'
            },
            label: {
                color: 'gray'
            },
            card: {
                maxWidth: 345
            },
            cardMedia: {
                height: 140
            },
            cardAction: {
                width: '100%',
                justifyContent: 'center'
            }

        }


        let nextButton = (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={this.props.nextHandler}
          >
            Next
          </Button>);
      
          if(!this.allAreChecked()){
            nextButton = (
              <Tooltip title="Acknowledge all conditions before proceeding">
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled
                  onClick={this.props.nextHandler}
                >
                  Next
                </Button></span>
              </Tooltip>
            );
          }



        return (
            <div>

                <Paper variant="outlined" style={stylesheet.paper}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={2}>
                                <Grid item xs={12}>
                                    <Box m={4}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            alignItems="flex-start"
                                            spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography variant="overline">
                                                    <Box display="inline" fontWeight="fontWeightBold" fontSize={24}>Car Information</Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="overline" noWrap>
                                                    <Box display="inline" fontSize={12}>5 min</Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" align="left">
                                                    <Box display="inline" fontWeight="fontWeightLight">
                                                        This section includes seller-disclosed information about the car you are going
                                                        to purchase. Remember to get an independent inspection by a mechanic before
                                                        you're ready to buy the car.
                                                    </Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                            <Box m={2}>
                                <FaqSection section="carinfo"/>
                            </Box>
                            <Divider/>
                        </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box ml={4}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="flex-start"
                                            alignItems="stretch"
                                            spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="overline">
                                                    Vehicle History
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Grid container direction="row" justify="space-evenly" alignItems="baseline">
                                                            <Grid item xs={4}>
                                                                <Typography variant="body2" align="center" fullWidth style={stylesheet.label}>
                                                                    Previous Owners
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="body2" align="center">
                                                                    {this.state.numOwner}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Grid container direction="row" justify="space-evenly" alignItems="baseline">
                                                            <Grid item xs={4}>
                                                                <Typography variant="body2" align="center" fullWidth style={stylesheet.label}>
                                                                    Major Accidents
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="body2" align="center">
                                                                    {this.state.numAccident}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Divider/>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Grid container direction="row" justify="space-evenly" alignItems="baseline">
                                                            <Grid item xs={4}>
                                                                <Typography variant="body2" align="center" fullWidth style={stylesheet.label}>
                                                                    Title Status
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="body2" align="center">
                                                                    {this.state.titleType}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={4}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="flex-start"
                                            spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="overline">
                                                    Seller Disclosures
                                                </Typography>
                                            </Grid>

                                            {this
                                                .state
                                                .disclosures
                                                .map((disclosure, index) => {
                                                    return (

                                                        <Grid item xs={12} md={6} key={index}>
                                                            <Box>
                                                                <Card className={stylesheet.card} variant="outlined">
                                                                    <CardActionArea>
                                                                        <CardMedia
                                                                            className={stylesheet.cardMedia}
                                                                            image=""
                                                                            title="Contemplative Reptile"/>
                                                                        <CardContent>
                                                                            <Typography variant="body2" color="textSecondary" component="p">
                                                                                {disclosure}
                                                                            </Typography>
                                                                        </CardContent>
                                                                    </CardActionArea>
                                                                    <CardActions style={stylesheet.cardAction}>
                                                                        <FormControlLabel
                                                                            control={< Checkbox 
                                                                              checked = {this.state.checked[index.toString()]}
                                                                              onChange = {(e) => this.handleCheckChange(e, index.toString())}
                                                                             name = "Acknowledge" />}
                                                                            label="Acknowledge"/>

                                                                    </CardActions>
                                                                </Card>
                                                            </Box>
                                                        </Grid>

                                                    );
                                                })}

                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box ml={4} mb={4} ml={4} mr={4}>
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item xs={4}>
                                                <Button fullWidth onClick={this.props.backHandler}>
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {nextButton}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>

            </div>
        );
    }
}

export default withFirebaseAuth(BasicDocs);

import { Button, IconButton, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';




import Invite from './Invite'
import React from 'react';
import CopyLink from './CopyLink';
import Acknowledge from './Acknowledge';
import Buyers from './Buyers';
import {withFirebase} from '../../../Firebase';

class BuyerStatus extends React.Component {
    constructor(props) {
        super(props);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.state = {
            confirmed: false,
            canNext: false,
            preventSend: false //whether to prevent someone from sending invite because a buyer has already payed
        }
        this.handleCanNext = this.handleCanNext.bind(this);
        this.updateBuyerStatus = this.updateBuyerStatus.bind(this);
    }

    handleConfirm(val){
        if(val){
            this.setState({confirmed: true});
        }
        
    }


    updateBuyerStatus(){
        this.props.firebase.buyerClaimedUid(this.props.token).on('value', uidSnapshot=>{
            this.setState({preventSend: !!uidSnapshot.val()})
        });
        
    }


    handleCanNext(val){
        this.setState({canNext: val});
    }

    componentDidMount(){
        this.updateBuyerStatus();
    }
   


   

    render() {

        const stylesheet = {
            paper: {
                minHeight: '50vh'
            },
            pdfBox: {
                height: '25vh'
            }

        }

        return (
            <div>

                <Paper variant="outlined" style={stylesheet.paper}>
                <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={12} md={8}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        spacing={2}>
                    
                        <Grid item xs={12}>
                            <Box m={4}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-start"
                                    spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography variant="overline">
                                            <Box display="inline" fontWeight="fontWeightBold" fontSize={24}>Invite & Manage Buyers</Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="overline" noWrap>
                                            <Box display="inline" fontSize={12}>2 min</Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">
                                            In this section, you can invite prospective buyers to buy your car and track
                                            their progress. You can only go to the next step once someone has paid for your
                                            car.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Box m={4}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="stretch"
                                    alignItems="flex-start"
                                    spacing={0}>
                                    <Grid item xs={12} style={{marginTop:"50px"}}>
                                        <Typography variant="subtitle2" align="left">
                                            Confirm Information
                                        </Typography>
                                        <Typography variant="body2">
                                            <Box display="inline" fontSize={12}>
                                                Confirm the information regarding your sale before inviting potential buyers.
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop:"30px"}}>
                                        <Acknowledge token={this.props.token} onConfirm={this.handleConfirm}/>
                                    </Grid>
                                    
                                    <Grid item xs={12} style={{marginTop:"50px"}}>
                                        <Typography variant="subtitle2" align="left">
                                            Invite Buyers
                                        </Typography>
                                        <Typography variant="body2">
                                            <Box display="inline" fontSize={12}>
                                                Send prospective buyers the link below or send a them an invite with the widget below
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12} style={{marginTop:"15px"}}>
                                        <Grid container justify = "center" >
                                            <CopyLink token={this.props.token} disabled={!this.state.confirmed}/>
                                        </Grid>
                                        
                                    </Grid>

                                    <Grid item xs={12} style={{marginTop:"10px"}}>
                                        <Typography variant="body2">
                                            <Box display="inline" fontSize={12}>
                                                Or send text invite
                                            </Box>
                                        </Typography>
                                    </Grid>

                                    
                                    <Grid item xs={12}>
                                        <Grid container justify = "center">
                                            <Invite token={this.props.token} disabled={!this.state.confirmed || this.state.preventSend}/>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} style={{marginTop:"50px"}}>
                                        <Typography variant="subtitle2" align="left">
                                            Manage Buyers
                                        </Typography>
                                        <Typography variant="body2">
                                            <Box display="inline" fontSize={12}>
                                                View the statuses of buyers in the process of making the purchase
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop:"10px"}}>
                                        <Buyers token={this.props.token} disabled={!this.state.confirmed} onChange={this.handleCanNext} preventSend={this.state.preventSend}/>
                                    </Grid>
                                    
                                </Grid>
                                   
                            </Box>
                            <Divider/>
                        </Grid>

                        <Grid item xs={12}>
                                    <Box ml={4} mb={4}>
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item xs={6}>
                                                <Box m={2}>
                                                    <Button fullWidth onClick={this.props.backHandler}>
                                                        Back
                                                    </Button>
                                                </Box>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box m={2}>

                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        onClick={this.props.nextHandler}>
                                                        Next
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                </Paper>

            </div>
        );
    }
}

export default withFirebase(BuyerStatus);

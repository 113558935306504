import React from 'react';

import UploadDialogue from './UploadDialogue';
import TITLE_STATUS from './constants';

import {withFirebase} from '../../../../Firebase'
import Button from '@material-ui/core/Button';

class DialogueHandler extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            dialogueOpen: false
        }
        this.handleModalChange = this.handleModalChange.bind(this);
    }

    handleModalChange(){
        this.setState({dialogueOpen : !this.state.dialogueOpen});
    }


    render(){
        return(
            <React.Fragment>
                <Button onClick={this.handleModalChange}>Upload</Button>
                <UploadDialogue token={this.props.token} open={this.state.dialogueOpen} onChange={this.props.onChange} onClose={this.handleModalChange}/>
            </React.Fragment>
        );
    }
}

export default withFirebase(DialogueHandler);
import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Grid, Box, Typography } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {withFirebaseAuth} from '../../../../Firebase';
const MINUTE_SECONDS = 60;
const HOUR_SECONDS = 3600;
const DAY_SECONDS = 86400;
const DAY_MILLISECONDS = 86400*1000;
const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6
};

const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };
  
  const getTimeSeconds = time => (MINUTE_SECONDS - Math.ceil(time)) | 0;
  const getTimeMinutes = time => ((time % HOUR_SECONDS) / MINUTE_SECONDS) | 0;
  const getTimeHours = time => ((time % DAY_SECONDS) / HOUR_SECONDS) | 0;
  const getTimeDays = time => (time / DAY_SECONDS) | 0;
  

export default function Countdown(props) {
    const startTime = props.titleUploadedTime.getTime();
    const endTime = startTime + (2*DAY_MILLISECONDS); // use UNIX timestamp in seconds   console.log(endTime);
    const currentTime = new Date();
    var remainingTime = (endTime - currentTime.getTime())/1000;
    
   
    let message = props.message;
    if(remainingTime < 0){
        remainingTime = 0;
        message = "You're all set! We will transfer funds as soon as we can."
    }
    const days = Math.ceil(remainingTime / DAY_SECONDS);
    const daysDuration = days * DAY_SECONDS;
  return (
    <div>
        
        <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12}>
            <Typography variant="overline">
                {message}
            </Typography>

            <Box display="inline">
                <Tooltip title="This countdown starts after you upload the transferred title to this page.">
                    <IconButton aria-label="info">
                        <InfoIcon fontSize="small"/>
                    </IconButton>
                </Tooltip>
            </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item xs={4}>
                <CountdownCircleTimer
            {...timerProps}
            colors={[["#360D73"]]}
            duration={daysDuration}
            initialRemainingTime={remainingTime}
        >
            {({ elapsedTime }) =>
            renderTime("days", getTimeDays(daysDuration - elapsedTime))
            }
        </CountdownCircleTimer>
                </Grid>
                <Grid item xs={4}>

        <CountdownCircleTimer
            {...timerProps}
            colors={[["#5D1AC0"]]}
            duration={DAY_SECONDS}
            initialRemainingTime={remainingTime % DAY_SECONDS}
            onComplete={totalElapsedTime => [
            remainingTime - totalElapsedTime > HOUR_SECONDS
            ]}
        >
            {({ elapsedTime }) =>
            renderTime("hours", getTimeHours(DAY_SECONDS - elapsedTime))
            }
        </CountdownCircleTimer>
        </Grid>
        <Grid item xs={4}>

        <CountdownCircleTimer
            {...timerProps}
            colors={[["#8C67C2"]]}
            duration={HOUR_SECONDS}
            initialRemainingTime={remainingTime % HOUR_SECONDS}
            onComplete={totalElapsedTime => [
            remainingTime - totalElapsedTime > MINUTE_SECONDS
            ]}
        >
            {({ elapsedTime }) =>
            renderTime(
                "minutes",
                getTimeMinutes(HOUR_SECONDS - elapsedTime)
            )
            }
        </CountdownCircleTimer>
        </Grid>
      
            </Grid>
        
        </Grid>
        </Grid>
      
    </div>
  );
}

import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';

const LayoutLoggedIn = ({ children }) => (
  <React.Fragment>
    <Header navPosition="right" hideNav/>
    <main className="site-content" style={{minHeight:"90vh"}}>
      {children}
    </main>
    <Footer style={{minHeight:"10vh"}}/>
  </React.Fragment>
);

export default LayoutLoggedIn;  
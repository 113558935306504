import React from 'react';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  }
}));

function getSteps() {
  return ['Your Info', 'Basic Docs', 'Payment Details', 'Next Steps']; 
}

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 14,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
  },
  active: {
    '& $line': {
      borderColor: '#9c74d8',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#9c74d8',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);



export default function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  // const [activeStep, setActiveStep] = React.useState(0);
  const activeStep = props.activeStep;
  const setActiveStep = props.setActiveStep;

  const steps = props.steps;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

import React from 'react'
import {withFirebase} from '../../Firebase';
import Doc from './Doc'
import Signature from './Signature'

import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Grid,
    
} from '@material-ui/core'
import SnackbarAlert from '../SnackbarAlert';



function getDate(){
    var date = new Date();
    return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
}
function getAddress(infoObj){
    let a = infoObj.address1;
    if(infoObj.address2){
        a += " " + infoObj.address2;
    }
    a += `, ${infoObj.city}, ${infoObj.state} ${infoObj.zip}`
    return a
}

function getCarName(carInfo){
 return `${carInfo.make} ${carInfo.model} ${carInfo.year}`;
}

function formatDisclosures(disclosures){
    return disclosures.join(" ")
}

function getFullName(info){
    return `${info.firstname} ${info.lastname}`;
}


class DocWrapper extends React.Component{
    constructor(props){
        super(props);

        this.getData = this.getData.bind(this);
        this.advanceStep = this.advanceStep.bind(this);

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getUserHasSigned = this.getUserHasSigned.bind(this);

        this.state = {
            isLoading: true,
            data: {},
            buyerSignature: null,
            sellerSignature: null,
            step:0,
            open: false,
            error: false
        }


    }

    componentDidMount(){
        this.getData().then((dataResult)=>{
            this.setState({isLoading: false, data: dataResult})
        }).catch(e=>{
            this.setState({isLoading: false, error: e.message})
        });
        
        

        this.props.firebase.signatures(this.props.token).on('value', snapshot=>{
            const val = snapshot.val();
            if(val){
                let updateObj = {}
                if(val.seller){
                    updateObj['sellerSignature'] = val.seller; 
                }
                if(val.buyer){
                   updateObj['buyerSignature'] = val.buyer;
                }
                
                this.setState(updateObj, ()=>{
                    this.props.onChange(this.getUserHasSigned());
                });
            }
             
        });
    }

    async getData(){
        const transactionId = this.props.token;
        const buyerId = await this.props.firebase.buyerClaimedUid(transactionId).once("value");
        if(!buyerId.val()){
            throw new Error("Buyer has Not yet payed");
        }

        
        

        const results = await Promise.all([
             this.props.firebase.sellerBasicInfo(transactionId).once("value"),
             this.props.firebase.sellerCarInfo(transactionId).once("value"),
             this.props.firebase.price(transactionId).once("value"),
             this.props.firebase.disclosures(transactionId).once("value"),
             this.props.firebase.userBasicInfo(transactionId, buyerId.val()).once("value"),
        ]);
        results.forEach(r=>{
            if(!r.exists()){
                throw new Error("Could Not Read all necessary info");
            }
        })

        
        const sellerInfo = results[0].val();
        const carInfo = results[1].val();
        const price = results[2].val();
        const disclosures = results[3].val();
        const buyerInfo = results[4].val();

        
        
        
       
        return {
            buyername: getFullName(buyerInfo),
            sellername: getFullName(sellerInfo),
            buyeraddress: getAddress(buyerInfo),
            selleraddress: getAddress(sellerInfo),
            date: getDate(),
            state: sellerInfo.state,
            zip: sellerInfo.zip,
            price: price,
            carname: getCarName(carInfo),
            mileage: carInfo.mileage,
            vinlic: carInfo.vinlic,
            disclosures: formatDisclosures(disclosures)
        }
    }

    placeholder(){this.setState({step: 0, open: false})

    }

    advanceStep(){
        if(this.state.step==2){
            this.setState({step: 0, open: false})
        }else{
            this.setState({step: this.state.step+1})
        }
        

    }

    handleClose(){
        this.setState({open: false})
    }

    handleOpen(){
        this.setState({open: true});
    }

    getUserHasSigned(){
        let userHasSigned = (!!this.state.sellerSignature && this.state.sellerSignature!== "NONE");
        if(!this.props.isSeller){
            userHasSigned = (!!this.state.buyerSignature && this.state.buyerSignature !== "NONE");
        }
        return userHasSigned;
    }

    render(){
        
        if(this.state.isLoading){
            return  <SnackbarAlert open={this.state.isLoading} message={"Loading"} onClose={()=>{this.setState({isLoading:false})}}/>

        }else if(!!this.state.error){
            return <SnackbarAlert open={!!this.state.error} message={this.state.error}/>
        }else{ 


            let document = ( <PDFLoader data={this.state.data} buyerSignature={this.state.buyerSignature} sellerSignature={this.state.sellerSignature}/>);
            // let document2 = (<Doc data={this.state.data} buyerSignature={this.state.buyerSignature} sellerSignature={this.state.sellerSignature}/>)
            let userHasSigned = this.getUserHasSigned();
            

            // alert(`rendering ${this.state.isLoading} ${JSON.stringify(this.state.data)}`);
           return (

               <React.Fragment>
                <Button variant="contained" color="primary" onClick={this.handleOpen}> {userHasSigned ? "View" : "Sign"} </Button>
                
                    
                
                <Dialog 
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {this.state.step==2 ? "Signed Document" : "Sign Document"}
                    </DialogTitle>
                    {this.state.step === 0 &&
                        <React.Fragment>
                            <DialogContent dividers>
                                    {document}   
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={this.advanceStep} color="primary">
                                    Sign
                                </Button>
                            </DialogActions>
                        </React.Fragment>
                    }
                    {this.state.step === 1 &&
                        <Signature 
                            token={this.props.token}
                            handleAdvance={this.advanceStep}
                            isSeller={this.props.isSeller}
                        />                            
                    }
                    {this.state.step === 2 && 
                    
                    <React.Fragment>
                    <DialogContent dividers>
                        {document}
                    </DialogContent>

                    <DialogActions>
                            <Button onClick={this.advanceStep} color="primary">
                                Finish
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                    
                    
                    }

                </Dialog>

                
                
                
                </React.Fragment>
           ); 
        }
    }
}

class PDFLoader extends React.Component{
    constructor(props){
        super(props);
        this.state = {loaded: false};
    }
    componentDidMount(){
        setTimeout(()=>{
            this.setState({loaded:true});
        },1);
    }

    render(){
        if(this.state.loaded){
            return (
                <PDFViewer width="550" height="600">
                        <Doc {...this.props}/>
                </PDFViewer>
            );
        }else{
            return null;
        }
    }
}

export default withFirebase(DocWrapper)
import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link, withRouter } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';

import MuiPhoneNumber from  'material-ui-phone-number'
import shortid from 'shortid'
import {Alert} from '@material-ui/lab'
import {Grid, Typography, TextField, Box, Divider} from '@material-ui/core'


const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class GetStartedPage extends React.Component {
  constructor(props){
    super(props);
    shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_@');
    this.submit = this.submit.bind(this);
    this.generateMessage = this.generateMessage.bind(this);
    this.generateHtmlMessage = this.generateHtmlMessage.bind(this);

    this.state = {
      buyerPhone: "",
      sellerPhone: "",
      code: shortid.generate(),
      hasSubmitted: false,
      name: '',
      error: null
    }

  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  
  generateMessage(){

    return `${this.capitalizeFirstLetter(this.state.name)} is interested in buying your car and using Crow to pay and handle all the paperwork. There is no cost to the seller, and it makes the whole process a lot faster. Use this code ${this.state.code} at buywithcrow.com or get started with this link: ${this.getCurrentUrl()}/sellerreferral/${this.state.code}`
  }
  generateHtmlMessage(){
    return <>
            is interested in buying your car and using Crow to pay and handle all the paperwork. It makes the whole process a lot faster. Use this code {this.state.code} at buywithcrow.com or get started with this link: <Link to={"/sellerreferral/" + this.state.code}>{this.getCurrentUrl()+"/sellerreferral/" + this.state.code}</Link>
          </>
  }
  getCurrentUrl(){
    return window.location.origin;
  }

  async submit(e){
    e.preventDefault();

    if(this.state.buyerPhone.length < 9 || this.state.sellerPhone.length < 9){
      this.setState({error: "Invalid Phone Numbers"});
    }else if(this.state.name.length < 1){
      this.setState({error: "Enter a name"});
    }else{
       const resp = await fetch('/api/getstartedbuyer',
          {method: 'POST',
          body: JSON.stringify(
            {
              
              transactionId: this.state.code,
              buyerPhone: this.state.buyerPhone,
              sellerPhone: this.state.sellerPhone,
              message: this.generateMessage()
          })});
        const respJSON = await resp.json();
        if(respJSON.status === "SUCCESS"){
          this.props.firebase.analytics.logEvent("buyer_sent_referral");    
          this.setState({hasSubmitted: true})
        }else{
          this.setState({error: "Sorry, Failed to send message. Double check your info or try again later."})
        }
      
    }
  }


  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Invite Seller',
    };

    

    if(this.state.hasSubmitted){
      return (
        <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <Grid container direction="column" justify="center" alignItems="center" spacing={4}> 
              <Grid item xs={12} md={6}>
                <Typography variant="overline">
                  <Box mb={1}>
                    Thank you
                  </Box>
                </Typography>
                   <Typography variant="body2">
                   We will text you at {this.state.buyerPhone} once we are ready for you to complete the transaction.

                    </Typography>
              </Grid>
            
              <Grid item xs={12} md={6}>
                      <Button  to="/" color="secondary" wide>Back To Home</Button>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Divider/>
              <div className="mb-12">
                    <h5>How it works?</h5>
                    <p>Enter the phone number of you and the person you want to buy the car from. We will send them a text inviting them to start the purchase process, and update you once we are ready for you to pay</p>
                  </div>
              </Grid>
              
            </Grid>
            
          </div>
        </div>
      </section>

      );
    }



    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <Grid container direction="column" justify="center" alignItems="center" spacing={4}> 
              <Grid item xs={12} md={6}>
                <Typography variant="overline">
                  <Box mb={1}>
                    Invitation Message
                  </Box>
                </Typography>
                   <Typography variant="body2">
                     <Box borderRadius={16} lineHeight={2} bgcolor="#F1F1F1" color="gray" fontStyle="italic" p={2} border={0}>
                      <Box display="inline">
                      <TextField onChange={(e)=>this.setState({name: e.target.value})} value={this.state.name} type="text" placeholder="Your name" InputLabelProps={{textAlign: 'center'}}/> 
                      </Box>
                        {this.generateHtmlMessage()}
                     </Box>
                    </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                      <MuiPhoneNumber
                          defaultCountry={'us'}
                          label="My Phone Number"
                          onChange={val=>{this.setState({buyerPhone: val})}}
                          variant="outlined"
                          fullWidth
                      />
              </Grid>
              <Grid item xs={12} md={6}>
                    <MuiPhoneNumber
                        defaultCountry={'us'}
                        onChange={val=>{this.setState({sellerPhone: val})}}
                        label="Seller Phone Number"

                        variant="outlined"
                        fullWidth
                    />
              </Grid>
              <Grid item xs={12} md={6}>
                      <Button onClick={this.submit} color="primary" wide>Send Invite</Button>
              </Grid>
              {!!this.state.error &&
                  <div className="mb-12">
                      <Alert severity="warning">{this.state.error}</Alert>
                  </div>
                  }
              <Grid item xs={12} md={6}>
                <Divider/>
              <div className="mb-12">
                    <h5>How it works?</h5>
                    <p>Enter the phone number of you and the person you want to buy the car from. We will send them a text inviting them to start the purchase process, and update you once we are ready for you to pay</p>
                  </div>
              </Grid>
              
            </Grid>
            
          </div>
        </div>
      </section>
    );
  }
}

GetStartedPage.propTypes = propTypes;
GetStartedPage.defaultProps = defaultProps;

export default withRouter(GetStartedPage);
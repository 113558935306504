import React from 'react';
import LoginForm from '../components/sections/LoginForm';
import BuyerSellerLogin from '../components/sections/BuyerSellerLogin';

class Login extends React.Component {
  render() {
    return (
      <BuyerSellerLogin className="illustration-section-01" />
    );
  }
}

export default Login;
import React from 'react'
import {Link} from 'react-router-dom'

import LayoutLoggedIn from '../../Landing/src/layouts/LayoutLoggedIn'
import Checkout from '../../Landing/src/components/sections/Checkout'

class BuyHistory extends React.Component {
    render() {
        return (
            <LayoutLoggedIn>
                <Checkout views={this.props.views} token={this.props.token}/>
            </LayoutLoggedIn>

        );
    }
}

export default BuyHistory
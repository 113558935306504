import React from 'react';
import { withFirebase } from '../../../../Firebase';
import CountDown from '../Countdown';
import firebase from 'firebase';
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import TITLE_STATUS from '../Title/constants'
class PaymentStatus extends React.Component{

    //expects input props of pros
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            titleUploadedTime: null,
            status: TITLE_STATUS.notuploaded,
            sellerPayed: false,
            customMessage: null,
            customSeverity: 'info'
        }
        this.updateCountdownTime = this.updateCountdownTime.bind(this);
        this.getSellerPayed = this.getSellerPayed.bind(this);
        this.getCustomMessage = this.getCustomMessage.bind(this);
    }

    
    componentDidMount(){
        this.updateCountdownTime();
        this.getSellerPayed();
        this.getCustomMessage();
    }

    getCustomMessage(){
        this.props.firebase.sellerCustomMessage(this.props.token).on('value', result=>{
            const val = result.val();
            if(!!val && !!val.message && !!val.severity){
                this.setState({
                    customMessage: val.message,
                    customSeverity: val.severity
                })
            }
        })
    }

    

    updateCountdownTime(){
        this.props.firebase.titleDate(this.props.token).on('value', (result)=>{
          const vals = result.val();
          if(!!vals && !!vals.seconds && !!vals.nanoseconds){
              const startDateTimeStamp = new firebase.firestore.Timestamp(vals.seconds, vals.nanoseconds)
              this.setState({titleUploadedTime: startDateTimeStamp.toDate(), isLoading: false});
          }else{
              this.setState({isLoading:false})
          }
        });
        this.props.firebase.titleStatus(this.props.token).on('value', (result)=>{
            const status = result.val();
            this.setState({status: status || TITLE_STATUS.notuploaded})
        });
          
      }
      
      getSellerPayed(){
          this.props.firebase.paymentTransferred(this.props.token).on('value',  (result)=>{
            this.setState({sellerPayed: result.val()});
        });
      }

    render(){
        if(this.state.isLoading){
            return null;
        }
        
        if(!!this.state.customMessage){
           return (
            <Box mt={2}>
                <Alert severity={this.state.customSeverity}>{this.state.customMessage}</Alert>
            </Box>);
        }
        if(this.state.sellerPayed){
            return <PaymentMessage/>
        }

        if(!this.props.stepscompleted && this.state.status === TITLE_STATUS.notuploaded){
            return <NoPaymentMessage/>;
        }
    
        

        if(this.state.status !== TITLE_STATUS.notuploaded && !this.props.stepscompleted){
            return (
                <Box mt={4}>
                    <CountDown titleUploadedTime={this.state.titleUploadedTime} message="You will be payed out once you complete all steps and 48 hours has elapsed after uploading the title"/>
                </Box>
            );
        }

        if(this.props.stepscompleted){
            return <CountDown titleUploadedTime={this.state.titleUploadedTime} message="You will be payed out once once the time has elapsed"/>
        }

        return <h1>Error</h1>
    }
}


function NoPaymentMessage(props){
    return (
        <Box m={2}>
            <Alert severity="info">
                You will be payed once you have both completed all steps and 48 hours have elapsed since you uploaded the title
            </Alert>
        </Box>
    );
}

function PaymentMessage(props){
    return (
        <Box m={2}>
            <Alert severity="success"> You funds have been transferred. For more details view your linked stripe account. </Alert>
        </Box>
    );
}

export default withFirebase(PaymentStatus);
import React from 'react'

import {Button, FormControl, TextField} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import faqs from './faq';

function FaqSection(props){
  const content = faqs[props.section].content;
    const accordionComponent = (
      <Box m={2}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography align="center" variant="overline">
              {faqs[props.section].title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
          
            <Grid
            container
            spacing={2}
          >
            {content.map((step)=>
            <Grid item xs={12}>
            <Grid
              container
              direction="row"
              spacing={2}
            >
              <Grid item md={12} xl={6}>
                <Typography variant="body1">
                  <Box fontWeight="fontWeightBold">
                    {step.q}
                  </Box>
                </Typography>
              </Grid>
              <Grid item md={12} xl={6}>
                <Typography variant="body2">
                  <Box fontWeight="fontWeightLight">
                  {step.a}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
            )}
            </Grid>
          
          </AccordionDetails>
          </Accordion>
          </Box>
    );
    return accordionComponent;
}
export default FaqSection;
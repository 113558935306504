import { Button } from '@material-ui/core';
import React from 'react';
import { IndividualStep } from '../../../General/NextSteps';
import BillOfSale from '../../../General/BillOfSale/DocWrapper';
import directionsByState from './directionsByState.json';


class Steps extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            bill: false //boolean to track whether bill of sale was signed
       }
        this.setBill = this.setBill.bind(this);

    }
    
    getComponent(button){
        if (!button) {
            return null;
        }
        if (button.url) {
            return <Button href={button.url}>{button.text}</Button>
        } else if (button.review) {
            return <BillOfSale token={this.props.token} onChange={this.setBill}/>;
        } else {
            return null;
        }
    }

    getStateIndex(targetState){
        var index = -1;
        var filteredObj = directionsByState.find(function(item, i){
          if(item.state === targetState){
            index = i;
            return i;
          }
        });
        return index
    }


    getComplete(button){
        if (button && button.review) {
            return this.state.bill;
        }
        return true;
    }

    setBill(val){
        this.setState({bill: val}, this.callOnChange);
    }

    
    render(){
        const stateIndex = this.getStateIndex(this.props.sellerState);
        const instructions = directionsByState[stateIndex]; 
        return (
            instructions.steps.map((step, index) => {
                return (
                    <React.Fragment key={`key-${index}`}>
                        <IndividualStep
                        complete={this.getComplete(step.button)}
                        number={index + 1}
                        text={step.text}
                        ActionComponent={this.getComponent(step.button)}/>
                    </React.Fragment>
                );
            })
        );   
    }
}

export default Steps;
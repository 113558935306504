import React from 'react';


import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { FormControl, TextField, Button } from '@material-ui/core';
import NumberFormatter from '../../General/NumberFormatter';

import {withFirebase} from '../../Firebase';
import authRequest from '../../General/AuthRequest';

class CarInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      make: "",
      mileage: "",
      model: "",
      year: "",
      vinlic: "",
      price: "",
      firstname: "",
      lastname: "",
      phone: "",
      isLoading: true,
    }


  }
  componentDidUpdate() {
  }
  componentDidMount() {
    authRequest(`/api/getcarinfo/${this.props.token}`, 'GET', {}, '')
      .then(data => {
        this.setState(Object.assign({}, data, {isLoading: false}));
        })

    
  }



  render() {
    const stylesheet = {
      paper:{
        minHeight: '7vh'
      }, 
      form:{
        '& .MuiTextField-root': {
          margin: '4px',
        },
      }

    }
    var zipcodes = require('zipcodes');
    return (
      <div>
            <Paper variant="outlined" style={stylesheet.paper}  style={this.state.isLoading? {height:200}: {}}>
                <Box m={4}>
                {!this.state.isLoading && 

                <Grid container direction="row" alignItems="flex-start" spacing={5}>
                    <Grid item xs = {6} md={8}>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                            <Typography variant="h5">
                                <Box align="left" m={1} fontWeight="fontWeightBold" fontSize={{}}>
                                    {this.state.carName}
                                </Box>
                            </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="overline">
                                    <Box align="left"  ml={1}>
                                        <NumberFormatter value={this.state.mileage}/> miles | {this.state.vinlic}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="overline">
                                    <Box align="left"  ml={1}>
                                        {this.state.location}
                                    </Box>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                  <Grid item xs = {6} md={4}>
                    <Box>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                <Typography variant="h5" align="right">
                                    <Box m={1} fontWeight="fontWeightBold">
                                    <NumberFormatter value={this.state.price} money/>
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="overline">
                                    <Box align="right"  mr={1}>
                                        Sold by {this.state.name}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="overline">
                                    <Box align="right"  mr={1}>
                                        480/427/7377
                                    </Box>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    </Grid>
                </Grid>
                }
                </Box>
            </Paper>
          
      </div>
    );
  }
}

export default withFirebase(CarInfo);

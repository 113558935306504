import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import React, { Component } from 'react';

import {Link} from 'react-router-dom';

class HeadBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var classes = {
      logo: {
        margin: 'auto',
        textAlign: 'center',
        width: '100%',
        maxHeight: '100%'
      },
      logoHorizontallyCenter: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      },
      menu: {
        position:'static',
        background: '#360D73',
        width: '100%',
        height: '90px', 
      }
    };

    return (
      <div  >
        <AppBar style={classes.menu}>
          <Toolbar>
            <Grid container>
              <Grid item xs={6} md={4}>
                  <Box m={2}>
                    <Link to={window.location.pathname.includes("seller")? '/seller': '/'}>
                    <img
                      src={'/logo_white_tire.png'}
                      className={classes.logo}
                      style={{ height: '3rem' }}
                      alt="logo"
                    />
                    </Link>
                  </Box>
              </Grid>
              <Grid item xs={6} md={8}>
                  
              </Grid>

            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default HeadBar;
import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link, withRouter } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import MuiPhoneNumber from  'material-ui-phone-number'

import {Alert} from '@material-ui/lab'
import BuyerLogin from './BuyerLogin';
import {Box} from '@material-ui/core'


const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class BuyerReferral extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);

    this.state = {
      buyerPhone: "",
      sellerPhone: "",
      hasSubmitted: false,
      error: null
    }
  }
  submit(e){
    e.preventDefault();

    if(this.state.buyerPhone.length < 9 || this.state.sellerPhone.length < 9){
      this.setState({error: "Invalid Phone Numbers"});
    }else{
      this.setState({hasSubmitted: true});
    }
  }
  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );
      const inputClasses = classNames(
        'form-input'
      );
    const sectionHeader = {
      title: 'Begin Sale',
    };


    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <div className="tiles-wrap">
              
           

            <BuyerLogin id={this.props.id}/>



            </div>
          </div>
        </div>
      </section>
    );
  }
}

BuyerReferral.propTypes = propTypes;
BuyerReferral.defaultProps = defaultProps;

export default withRouter(BuyerReferral);
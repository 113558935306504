import React from 'react'

import {withFirebase, withFirebaseAuthSecure} from '../Firebase'
import {withRouter} from 'react-router-dom'
import { LinearProgress } from '@material-ui/core';
import Stages from './Stages'

import Dashboard from './Dashboard'

import * as ROUTES from '../../constants/routes'

class CheckoutWrapper extends React.Component{
    constructor(props){
        super(props);
        this.update = this.update.bind(this);
        this.state = {
            myViews: [],
            isLoading: true,
            showCheckout: false
        }

        
    }

    componentWillReceiveProps(newProps){
        const token = newProps.match.params.token;
        this.update(token); 
    }

    componentDidMount(){
        const token = this.props.match.params.token;
        this.update(token);   
    }

    update(token){

        if(token==="FORGOT"){
            this.showBuyHistory();
        }else{
            this.props.firebase.buyerReady(token).once('value', snapshot=>{
            
                if(snapshot.exists() && snapshot.val()){
                    this.setState({isLoading: false, showCheckout: true});
                    let repObj = {};
                    repObj[token] = new Date();
                    this.props.firebase.buyHistory(this.props.authUser.uid).update(repObj);
                }else{
                    this.showBuyHistory();
                }
            });
        }
        
        
    }

    async getMakeModel(values){

        let carList = [];
        for(let ID in values){
            const snapshot = await this.props.firebase.sellerCarInfo(ID).once('value');
            if(!snapshot.val() || !snapshot.val().year ||!snapshot.val().make || !snapshot.val().model){
                carList.push({name: "Sale code: "+ID, transID: ID});
            }else{
                carList.push({name: snapshot.val().year +" "+ snapshot.val().make +" "+ snapshot.val().model, transID: ID});
            }
        }
        return carList;
    }

    showBuyHistory(){
            
        this.props.firebase.buyHistory(this.props.authUser.uid).once('value', snapshot=>{
            
            if(snapshot.exists()){
                this.getMakeModel(snapshot.val()).then(views=>{
                    console.log(views);
                    this.setState({
                        isLoading: false,
                        myViews: views
                    })
                });  
            }else{
                this.setState({isLoading: false})
            }
        });
    }


    render(){
        if(this.state.isLoading){
            return (<LinearProgress/>);
        }else if(this.state.showCheckout){
            return <Stages token={this.props.match.params.token}/>
        }else{
            return <Dashboard views={this.state.myViews} token={this.props.match.params.token}></Dashboard>
        }
    }
}

export default withRouter(withFirebaseAuthSecure(CheckoutWrapper))
import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Input from '../../components/elements/Input';
import Modal from '../../components/elements/Modal';
import axios from '../../../../../../node_modules/axios';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class HeroFull extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: '',
        priority_number: '',
        email_submitted: '',
        total_users: '',
        ref_link: '',
        error_info: '',
        demoModalActive: false,
        videoModalActive: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }
  handleModalOpen(event) {
    this.setState({demoModalActive: true});
    event.preventDefault();
  }
  handleModalClose(event) {
    this.setState({demoModalActive: false});
    event.preventDefault();
  }
  handleSubmit(event) {
    console.log('A name was submitted: ' + this.state.value);
    event.preventDefault();
    var body = {
        email: this.state.value,
        api_key: 'CN1DNF',
        referral_link: window.location.href
    };

    axios.post('https://www.getwaitlist.com/waitlist', body)
      .then((response) => {
          console.log(response)
          var registered_email = response['data']['registered_email']
          var current_priority = response['data']['current_priority']
          var total_waiters_currently = response['data']['total_waiters_currently']
          var referral_link = response['data']['referral_link']
          this.setState({
              email_submitted: registered_email,
              priority_number: current_priority,
              total_users: total_waiters_currently,
              ref_link: referral_link
          })
      })
      .catch((error) => {
          this.setState({error_info: 'Some error occured unfortunately. Check your value or let me know: bani (at) banisingh.io'})
          console.log(error.response)
      });
    }
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );
    const formStyle = {
      maxWidth: '420px',
      margin: '0 auto'
    }
    
    const modalFormStyle = {
      maxWidth: '320px',
      margin: '0 auto'
    }

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content">
              <h1 className="mt-0 mb-16 reveal-from-top" data-reveal-delay="150">
              The smartest way to buy or sell a car between strangers.
              </h1>
              <div className="container-xs">
                <p className="m-0 mb-32 reveal-from-top" data-reveal-delay="300">
                Handle legal paperwork, payments, and title transfer seamlessly and securely. Get it all done in a couple of minutes with fraud protection and no hassle. All for a flat, one-time fee.                </p>
                <div className="reveal-from-top" data-reveal-delay="450">
                  <Button tag="a" color="primary"  href="/getstarted/">
                    Start Now
                  </Button>
                  <Modal
              id="demo-modal"
              show={this.state.demoModalActive}
              handleClose={this.handleModalClose}
            >
              <div className="center-content">
                <h3 className="mt-16 mb-8">Hear from us when we launch!</h3>
                <p className="text-sm">
                  We won't send any emails besides a referral link to send to your friends!
                </p>
              </div>
              {
                this.state.email_submitted ? ("The email submitted is " + this.state.email_submitted):
              
              <form style={modalFormStyle} onSubmit={this.handleSubmit}>
                <div className="mb-12">
                  <Input
                    type="email"
                    label="Email Address"
                    placeholder="Your best email"
                    labelHidden 
                    onChange={this.handleChange}
                    value={this.state.value}/>
                </div>
                <Button color="primary" wide>Submit</Button>
              </form>
            }
              <div className="center-content mt-24">
                <a
                  className="text-xxs fw-500 tt-u"
                  aria-label="close"
                  onClick={this.handleModalClose}
                >No thanks!</a>
              </div>
            </Modal>
                </div>
              </div>
            </div>
            <div className="hero-figure reveal-from-bottom" data-reveal-delay="150">
              <a
                data-video="https://player.vimeo.com/video/471154436"
                href="#0"
                aria-controls="video-modal"
                onClick={this.openModal}
              >
                <Image
                  className="has-shadow"
                  src={require('../../assets/images/CrowVideoThumbnail.png')}
                  alt="Video"
                  width={712}
                  height={400} />
              </a>
            </div>
            <Modal
              id="video-modal"
              show={this.state.videoModalActive}
              handleClose={this.closeModal}
              video="https://player.vimeo.com/video/471154436"
              videoTag="iframe" />
          </div>
            </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
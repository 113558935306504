import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import '../../fonts.css';
import BuyerPage from '../Buyer';
import LandingPage from '../Landing';
import SellerPage from '../Seller';
import { withAuthentication } from '../Session';
import Redirect404 from './Redirect404';






const theme = createMuiTheme({
  typography: {
    fontFamily: "'Heebo', sans-serif",
  },
  palette: {
    primary: {
      main: '#442277',
    },
    secondary: {
      main: '#000000',
    },
  },
});
const responsiveTheme = responsiveFontSizes(theme);

const App = () => (
  <MuiThemeProvider theme={theme}>
  <Router>
      <Switch>
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route exact path={'/about'} component={LandingPage} />

      <Route exact path={'/signup'} component={LandingPage} />
       <Route exact path={'/dashboard'} component={LandingPage} />
      <Route exact path={'/login'} component={LandingPage} />
      <Route path={'/getstarted'} component={LandingPage} />
      <Route path={'/sellerreferral/:id'} component={LandingPage} />
      <Route path={'/buyerreferral/:id'} component={LandingPage} />
      <Route exact path={'/faq'} component={LandingPage} />
      <Route exact path={'/secondary'} component={LandingPage} />
      <Route path={ROUTES.CHECKOUT} component={BuyerPage} />
      <Route path={'/checkout'} component={BuyerPage} />

      <Route exact path={ROUTES.SELLER} component={LandingPage} />
      <Route path={ROUTES.SELLER_TOKEN} component={LandingPage} />
      <Route path={ROUTES.SELLERFLOW} component={SellerPage} />

      {/* 404 to landing page */}
      <Route component={Redirect404} />
   </Switch>
  </Router>
  </MuiThemeProvider>
);

export default withAuthentication(App);

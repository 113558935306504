import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



export default function ConfirmKnowledge(props) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        
        <TableBody>
        
        {props.contents.map((row, index)=>{

            return (
                <TableRow key={index}>
                    <TableCell style={{paddingLeft:"16px"}} component="th" scope="row">{row[0]}</TableCell>
                    <TableCell style={{paddingRight:"16px"}} align={row[0]==='Disclosures'? "": "right"} >{row[1]}</TableCell>
                </TableRow>     
            );
        })}
        
       
          
        </TableBody>
      </Table>
    </TableContainer>
  );
}
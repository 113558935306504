import React from 'react';
import LoginForm from '../components/sections/LoginForm';
import Checkout from '../components/sections/Checkout';

class BuyerDashboard extends React.Component {
  render() {
    return (
      <Checkout className="illustration-section-01" />
    );
  }
}

export default BuyerDashboard;
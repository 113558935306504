import firebase from 'firebase';

async function request(url, method, body, idToken){

    try{
        let requestOptions = {
            method: method, // *GET, POST, PUT, DELETE, etc.
            
            headers: {
            'Content-Type': 'application/json',
            'authorization': idToken,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            
        };
        if(method === 'POST' && !!body){
            requestOptions.body = JSON.stringify(body);
        }
        
    
            // Default options are marked with *
        const response = await fetch(url, requestOptions);
       
        // const t = await response.text();
        // alert(t);
        return response.json(); // parses JSON response into native JavaScript objects
    }catch(e){
       alert(e.message);
       return {error: "Not Authed"}
    }
    
}

export default request;
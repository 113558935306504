import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import {Link} from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import {withFirebaseAuth} from '../../../../Firebase'

import BuyerLogin from './BuyerLogin'
import SellerLogin from './SellerLogin'
import AlreadyLoggedIn from './AlreadyLoggedIn';

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

class BuyerSellerLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 0
        };
        this.changeTab = this
            .changeTab
            .bind(this);
    }
    changeTab(e, newValue) {
        this.setState({tab: newValue});
        e.preventDefault();
    }
    render() {

        const {
            className,
            topOuterDivider,
            bottomOuterDivider,
            topDivider,
            bottomDivider,
            hasBgColor,
            invertColor,
            ...props
        } = this.props;

        const outerClasses = classNames('signin section', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);

        const innerClasses = classNames('signin-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');
        const inputClasses = classNames('form-input');
        const sectionHeader = {
            title: 'Login'
        };

        return (
            <section className={outerClasses}>
                <div className="container">
                    <div className={innerClasses}>
                        <SectionHeader tag="h1" data={sectionHeader} className="center-content"/>
                        <div className="tiles-wrap">

                            <div className="tiles-item">
                                <Box>

                                    <Tabs
                                        value={this.state.tab}
                                        onChange={this.changeTab}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        centered>
                                        <Tab label="Buyer"/>
                                        <Tab label="Seller"/>
                                    </Tabs>
                                    <Box m={2}>
                                        <div className="tiles-item-inner">
                                            <form>
                                                <fieldset>
                                                    {!this.props.authUser && <React.Fragment>
                                                        <BuyerLogin
                                                            inputClasses={classNames('form-input')}
                                                            hide={this.state.tab !== 0}/>
                                                        <SellerLogin inputClasses={inputClasses} hide={this.state.tab !== 1}/>
                                                    </React.Fragment>
                                                  }

                                                {!!this.props.authUser &&
                                                    <AlreadyLoggedIn uid={this.props.authUser.uid} isBuyer={this.state.tab===0}/>
                                                  }

                                                </fieldset>
                                            </form>
                                            <div className="signin-bottom has-top-divider">
                                                <div className="pt-32 text-xs center-content text-color-low">
                                                    Don't you have an account?{' '}
                                                    <Link to="/" className="func-link">Join the waitlist.</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                </Box>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

BuyerSellerLogin.propTypes = propTypes;
BuyerSellerLogin.defaultProps = defaultProps;

export default withFirebaseAuth(BuyerSellerLogin);
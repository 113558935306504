import React from "react";

import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import FaqSection from '../../../General/FaqSection'

import Steps from './StepsWrapper'

class PaymentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankDetails: false,
      paymentReviewed: false,
      authorize: false,
      canProcede: false
    };
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCanProcede = this.handleCanProcede.bind(this);
    
  }
  handleCanProcede(val){
    this.setState({canProcede: val});
  }
  handleStateChange(e) {
    this.setState({ stateSelect: e.target.value });
    e.preventDefault();
  }

  render() {
    const stylesheet = {
      paper: {
        minHeight: "50vh",
      },
      pdfBox: {
        height: "25vh",
      },
    };

    return (
      <div>

        <Paper variant="outlined" style={stylesheet.paper}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={8}>
              <Box m={4}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                  spacing={0}
                >
                  <Grid item xs={12}>
                    <Typography variant="overline">
                      {" "}
                      <Box
                        display="inline"
                        fontWeight="fontWeightBold"
                        fontSize={24}
                      >
                        Payment Details
                      </Box>{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="overline" noWrap>
                      {" "}
                      <Box display="inline" fontSize={12}>
                        5 min
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" align="left">
                      We work with our partners Plaid and Stripe to allow you to
                      quickly link your bank account and pay for your new
                      purchase quickly! None of your bank details are held with
                      us, and we work with your bank to ensure a smooth
                      transfer.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                            <Box ml={4} mt={-2} m={2}>
              <FaqSection section="payment"/>
            
            </Box>
            <Divider/>

            </Grid>
            <Grid item xs={12} md = {8}>
              <Box m={4}>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={2}
                >
                <Grid item xs={12}>
                  <Typography variant="overline">
                    {" "}
                    <Box display="inline" fontSize={18}>
                      Pay by Third-Party Financing
                    </Box>{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {" "}
                    <Box display="inline" fontSize={12}>
                      Get in touch with us about how you can pay with a
                      pre-approved loan. We get back to you in under 48 hours.
                    </Box>
                  </Typography>
                </Grid>

                  <Grid item xs={12}>
                    <Typography variant="overline">
                      {" "}
                      <Box display="inline" fontSize={18} alignSelf="left">
                        Pay by Cash
                      </Box>{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {" "}
                      <Box display="inline" fontSize={12}>
                        We use a secure third-party to handle the bank details
                        to make it smooth as possible for you.
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Steps token={this.props.token} onCanProcede={this.handleCanProcede}/>
                  </Grid>


                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={4}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    <Button fullWidth onClick={this.props.backHandler}>
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={this.props.nextHandler}
                      disabled={!this.state.canProcede}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default PaymentDetails;

import { LinearProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { withFirebaseAuth } from '../Firebase';
import HeadBar from '../General/HeadBar';
import HorizontalLinearStepper from '../General/Stepper.js';
import BasicCarInfo from './Stages/CarInfo-1';
import BuyerStatus from './Stages/BuyerStatus-4';
import Disclosures from './Stages/Disclosures-3';
import NextSteps from './Stages/NextSteps-5';
import SellerInfo from './Stages/SellerInfo-2';

import Footer from '../Landing/src/components/layout/FooterLight';


import SnackbarAlert from '../General/SnackbarAlert';


class SellerFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {activeStep: -1, isLoading: true, alertOpen: false, alertMessage: ""};
    this.setActiveStep = this.setActiveStep.bind(this);
    this.getNextStep = this.getNextStep.bind(this);
    this.getPrevStep = this.getPrevStep.bind(this);
  }
  componentDidUpdate() {
  }
  componentDidMount() {
    this.props.firebase.sellerActiveStep(this.props.token).once('value', snapshot=>{
      this.setState({isLoading: false, activeStep: this.constrainStep(snapshot.val() || 0)});
    });

    this.props.firebase.buyerReady(this.props.token).on('value', snapshot=>{
      this.setState({buyerReady: snapshot.val() || false});
      if(this.state.activeStep){
        this.setActiveStep(this.state.activeStep);
      }
      
    });
  }

  constrainStep(step){
    let stepSet = step || 0;
      
    if(this.state.buyerReady && step < 3){
      stepSet = 3;
      this.setState({alertOpen: true, alertMessage: "Can't Edit Information After Confirming Sale Info"})
    }
    stepSet = Math.max(0, Math.min(4, stepSet ));
    return stepSet;
  }

  setActiveStep(step, excludeUpdate){
      this.props.firebase.analytics.logEvent(`seller_step_${step}`);
      let stepSet = this.constrainStep(step);
      
      this.setState({activeStep: stepSet});
      if(!excludeUpdate){
        this.props.firebase.sellerActiveStep(this.props.token).set(stepSet);
      }
      
  }



  getStepContent(step) {
    switch (step) {
      case 0:
        return(
        <BasicCarInfo token={this.props.token} nextHandler={this.getNextStep}/>);
      case 1:
        return(<SellerInfo token={this.props.token}  nextHandler={this.getNextStep} backHandler={this.getPrevStep}/>);
      case 2:
        return(<Disclosures token={this.props.token}  nextHandler={this.getNextStep} backHandler={this.getPrevStep}/>);
      case 3:
        return(<BuyerStatus token={this.props.token}  nextHandler={this.getNextStep} backHandler={this.getPrevStep}/>);
      case 4:
        return(<NextSteps token={this.props.token}  nextHandler={this.getNextStep} backHandler={this.getPrevStep}/>);
      default:
        return null;
    }
  }
  getNextStep(){
    this.setActiveStep(this.state.activeStep + 1);
  }
  getPrevStep(){
    this.setActiveStep(this.state.activeStep -1);
  }


  render() {

    if(this.state.isLoading){
      return (<LinearProgress/>);
    }

    return (
      <div>
        <HeadBar/>

        <Box mt={2} style={{width:"100vw"}}>
          <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
            

            
            <Grid item xs={12} md={9}>
              <HorizontalLinearStepper activeStep={this.state.activeStep} setActiveStep={this.setActiveStep}
              steps={['Car Info', 'Seller Info', 'Price','Invite Buyers', 'Next Steps']}/>
            </Grid>
            <Grid item xs={12} md={8}>
              {this.getStepContent(this.state.activeStep)}
            </Grid>
          </Grid>
        </Box>

        <Footer/>
        <SnackbarAlert open={this.state.alertOpen} onClose={()=>{this.setState({alertOpen: false})}} message={this.state.alertMessage}/>
        

      </div>
    );
  }
}

export default withFirebaseAuth(SellerFlow);


import React from 'react';
import GetStartedPage from '../components/sections/GetStartedPageBuyer';

class Login extends React.Component {
  render() {
    return (
      <GetStartedPage className="illustration-section-01" />
    );
  }
}

export default Login;
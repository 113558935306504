import {Box, LinearProgress} from '@material-ui/core';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {withFirebaseAuthSecure} from '../../../../Firebase';
import shortid from "shortid";
import * as ROUTES from '../../../../../constants/routes';
import ViewHistory from './ViewHistory'
import classNames from 'classnames';
import Alert from '@material-ui/lab/Alert';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Typography from '@material-ui/core/Typography';


/*
Seller In Between Page
*/


class Seller extends React.Component {
    constructor(props) {
        super(props);
        this.transactionId = this.props.match.params.token;
        this.update = this
            .update
            .bind(this);
        this.componentDidMount = this
            .componentDidMount
            .bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    
        this.newTransaction = this.newTransaction.bind(this);
        this.submitRefCode = this.submitRefCode.bind(this);
        this.state = {
            isLoading: true,
            invalidReferralCode: !!this.transactionId,
            pastTransactions: [],
            refCode: ""
            
        }

        shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_@');
        
    }
    submitRefCode(e){
        e.preventDefault();
        this.props.history.push(ROUTES.SELLER_TOKEN.replace(":token",this.state.refCode));
        if(this.state.refCode){
            this.update(this.state.refCode);
        }
    }

    async getMakeModel(values){

      let carList = [];
      for(let ID in values){
            const snapshot = await this.props.firebase.sellerCarInfo(ID).once('value');
            if(!snapshot.val() || !snapshot.val().year ||!snapshot.val().make || !snapshot.val().model){
                carList.push({name: "Sale code: "+ID, transID: ID});
            }else{
                carList.push({name: snapshot.val().year +" "+ snapshot.val().make +" "+ snapshot.val().model, transID: ID});
            }
        }
        return carList;
      }

    setPastTransactions() {
        this
            .props
            .firebase
            .sellHistory(this.props.authUser.uid)
            .once("value", snapshot => {
                if (snapshot.exists()) {
                    this.getMakeModel(snapshot.val()).then(myPastTransactions=>{
                      this.setState({
                        pastTransactions: myPastTransactions
                      });
                    })
                    
                }
            })
    }

    newTransaction(e){
        e.preventDefault();

        const newToken = shortid.generate();
        this.props.firebase.buyerReady(newToken).set(false);
        this.props.firebase.claimed(newToken).set(this.props.authUser.uid).then(s=>{
            this.props.history.push(ROUTES.SELLERFLOW.replace(":token",newToken));
        }).catch(e=>{
            alert(e)
        })

    }
    componentDidUpdate(){

    }
    componentDidMount() {
        const token = this.transactionId;
        this.setPastTransactions();
        if (token) {
            this.update(token);
        } else {
            this.setState({isLoading: false})
        }

    }

    update(token) {

        this
            .props
            .firebase
            .claimed(token)
            .once('value', snapshot => {
                const val = snapshot.val();
                if (snapshot.exists() && (val === this.props.authUser.uid || !val)) {
                    if(snapshot.exists() && !val){
                      this.props.firebase.analytics.logEvent('seller_accepted_referral')
                    }
                    this.props.history.push(ROUTES.SELLERFLOW.replace(":token",token))
                    if (!val) {
                        this
                            .props
                            .firebase
                            .claimed(token)
                            .set(this.props.authUser.uid);
                    }
                } else {
                    this.setState({isLoading: false, invalidReferralCode: true})
                }
            });
    }

    render() {
        const {
            className,
            topOuterDivider,
            bottomOuterDivider,      
            topDivider,
            bottomDivider,
            hasBgColor,
            invertColor,
            ...props
          } = this.props;
      
          const outerClasses = classNames(
            'signin section',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className
          );
      
          const innerClasses = classNames(
            'signin-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider'
          );
            const inputClasses = classNames(
              'form-input'
            );
          const sectionHeader = {
            title: 'My Sales',
          };

        if(this.state.isLoading){
            return (<LinearProgress/>)
        }
        return(
            <section
        
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <div className="tiles-wrap">
              
            <div className="tiles-item">
            <Box>

              <Box m={2}>
                <div className="tiles-item-inner">
                  <form>
                    <fieldset>
                    <div className="mt-24 mb-32">
                    <Button id="new-sale-button" onClick={this.newTransaction} color="primary" wide>New Sale</Button>
                    </div>
                    <div className="mt-24 mb-32">
                        <Typography variant="overline">
                            <Box m="auto" align="center">
                            or
                            </Box>
                        </Typography>
                    </div>
                    <div className="mb-12">
                    <Input
                        name="code"
                        label="Sale Code"
                        placeholder="123456"
                        onChange={(e)=>{this.setState({refCode: e.target.value})}}  
                        value={this.state.refCode}
                        />
                    </div>
                    

                    <div className="mt-24 mb-32">
                    <Button id="sign-in-button" onClick={this.submitRefCode} color="light" wide>Enter Sale</Button>
                    </div>
                    <div className="mb-12">
                    {this.state.invalidReferralCode && <Alert severity="error">Invalid Sale Code</Alert>
}
                    </div>
                    </fieldset>
                  </form>
                  <div className="signin-bottom has-top-divider">
                    <div className="pt-32 text-xs center-content text-color-low">
                    <ViewHistory history={this.state.pastTransactions}/>

                    </div>
                  </div>

                </div>
                </Box>
                </Box>


              </div>
              
            </div>
          </div>
        </div>
      </section>
        );
        
    }
}

export default withRouter(withFirebaseAuthSecure(Seller))
import React from "react";

import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import authRequest from '../../../General/AuthRequest';

import Link from './Steps/Link'
import Review from './Steps/Review'
import Authorize from './Steps/Authorize'
import Verify from './Steps/Verify'

import {withFirebaseAuth} from '../../../Firebase'
class StepsWrapper extends React.Component{
  constructor(props){
    super(props);
    
    this.state = {
      verifyCheck: false,
      linkCheck: false,
      reviewCheck: false,
      authorizeCheck: false,
      hasPayed: false,
      status: null,
      isLoading: true,
      transferWaiting: false
    }

    this.payOut = this.payOut.bind(this);
    this.handleVerifyChange = this.handleVerifyChange.bind(this);
    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.handleReviewChange = this.handleReviewChange.bind(this);
    this.handleAuthorizeChange = this.handleAuthorizeChange.bind(this);
    this.isReadyToPay = this.isReadyToPay.bind(this);
    this.updatePaymentStatus = this.updatePaymentStatus.bind(this);
    this.sendUpState = this.sendUpState.bind(this);

  }

  handleVerifyChange(val){
    this.setState({verifyCheck:val});
  }

  handleLinkChange(val){
    this.setState({linkCheck:val});
  }

  handleReviewChange(val){
    this.setState({reviewCheck:val});
  }

  handleAuthorizeChange(val){
    this.setState({authorizeCheck:val});
  }

  isReadyToPay(){
    const checked =  this.state.linkCheck && this.state.verifyCheck && this.state.authorizeCheck && this.state.reviewCheck;
    const eligibleToPay = !this.state.isLoading && this.state.status && this.state.status.status === "NO_PAYMENT";
    return checked && eligibleToPay;
  }


 
  
  componentDidMount(){
    this.updatePaymentStatus();
  }

  //call api to get current payment status
  async updatePaymentStatus(){

    // const resp = await fetch('/api/paymentstatus',
    //   {method: 'POST',
    //   body: JSON.stringify({uid: this.props.authUser.uid, transactionId: this.props.token})});
    // const respJSON = await resp.json();
    const respJSON = await authRequest('/api/paymentstatus', 'POST', {transactionId: this.props.token}, this.props.authUser.idToken);
    this.setState({status: respJSON, isLoading: false}, this.sendUpState);

    
  }

  sendUpState(){
    
    this.props.onCanProcede(this.state.status.status==="USER_PAYED");
  }
  getErrorMessage(status){
    if(status.includes('_')){
      return `Unknown Error ${status}`;
    }else{
      return status
    }
  }

  async payOut(){
    this.setState({transferWaiting: true});
    // const resp = await fetch('/api/checkoutcharge',
    //   {method: 'POST',
    //   body: JSON.stringify({uid: this.props.authUser.uid, transactionId: this.props.token})});
    // const respJSON = await resp.json();
    const respJSON = await authRequest('/api/checkoutcharge', 'POST', {transactionId: this.props.token}, this.props.authUser.idToken);
    
    const wasSuccesful = (respJSON.status === "USER_PAYED");

    this.setState({
      status: {
        status: respJSON.status,
        message: wasSuccesful ? "Payment Was Successful" : this.getErrorMessage(respJSON.status)
      },
      transferWaiting: false
    });

    this.props.onCanProcede(wasSuccesful);
  }

  render(){

    const alreadyPayed = this.state.status && this.state.status.status === "USER_PAYED";
    
    const sharedStepProps = {
      alreadyPayed,
      token: this.props.token
    }

    
    return(
      <>
      <Box m={2}>
        <Verify {...sharedStepProps} onChange={this.handleVerifyChange}/>
        <Link {...sharedStepProps} onChange={this.handleLinkChange}/>
        <Review {...sharedStepProps} onChange={this.handleReviewChange}/>
        <Authorize {...sharedStepProps} onChange={this.handleAuthorizeChange}/>
      </Box>
      <Box ml={2}>
        <PayButton onClick={this.payOut} disabled={!this.isReadyToPay()} transferwaiting={this.state.transferWaiting}> Transfer Funds </PayButton>
        <Warning status={this.state.status}/>
      </Box>
      </>
    );
  }
}

//expects props to have the same status parameter as returned by the api/paymentstatus endpoint
function Warning(props){
  if(!props.status || !props.status.status || props.status.status === "NO_PAYMENT"){
    return null;
  }

  const severityString = props.status.status==="USER_PAYED" ? "success" : "warning" 

  return(
      <Box mt={1}>
        <Alert severity={severityString}>{props.status.message}</Alert>
      </Box>
  );
  
}

function PayButton(props){
  if(props.disabled || props.transferwaiting ){
    return (
      <Tooltip title={props.transferwaiting? "Transfer In Progress" : "Complete all steps before you can transfer funds"}>
        <span>
          <Button variant="contained" color="primary" {...props}> Transfer Funds </Button>
          {props.transferwaiting && <CircularProgress CircularProgress size={30}/>}
        </span> 
      </Tooltip>
    );
  }else{
    return (<>
      <Button variant="contained" color="primary" {...props}> Transfer Funds</Button> 
      </>
    );
  }
  
}

export default withFirebaseAuth(StepsWrapper);

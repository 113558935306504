import { Button, FormControl, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withFirebase } from '../../../Firebase';
import Constants from '../../Constants';
import VALIDATOR from '../../../General/Validator'
import FaqSection from '../../../General/FaqSection'




class SellerInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            state: 'AL',
            zip: '',
            address1: '',
            address2: '',
            city: '',
            firstname: '',
            lastname: '',
            email: '',
            errors: {}
        };

        //fields that will be validated
        this.validateFields = new Set([
            "state",
            "zip",
            "address1",
            "address2",
            "city",
            "firstname",
            "lastname",
            "email"
        ])

        this.transactionId = this.props.token;
        
        this.handleChange = this
            .handleChange
            .bind(this);
        this.fbLoad = this
            .fbLoad
            .bind(this);
        this.fbSave = this
            .fbSave
            .bind(this);
        this.nextPage = this
            .nextPage
            .bind(this);
        this.validateInput = this
            .validateInput
            .bind(this);

    }

    //returns true if all inputs are valid, displays error messages if not
    validateInput() {

        let foundError = false;
        let errors = {};
        for (let field of this.validateFields) {

            if (!VALIDATOR[field](this.state[field])) {
                foundError = true;
                errors[field] = true;
            } else {
                errors[field] = false
            }
        }
        this.setState({errors: errors})
        return !foundError;
    }

    async fbLoad() {
        return this
            .props
            .firebase
            .sellerBasicInfo(this.transactionId)
            .once("value", snapshot => {
                if (snapshot.exists()) {
                    this.setState(snapshot.val())
                }
            });
    }

    //if save Errors, then save all of the errors in fb
    async fbSave() {
        let updateObj = {};
        for (let field of this.validateFields) {
            updateObj[field] = this.state[field];
        }
    
        return this
            .props
            .firebase
            .sellerBasicInfo(this.transactionId)
            .update(updateObj);
    }

    componentWillMount() {
        this.fbLoad();

    }
    handleChange(e) {
        let newObj = {};
        newObj[e.target.name] = e.target.value;
        this.setState(newObj);
    }

    //wrapper function for going to the next page
    async nextPage(e) {
        e.preventDefault();
        const isValid = this.validateInput();
        await this.fbSave();
        if (isValid) {
            this
                .props
                .nextHandler(e);
        }

    }

    render() {

        const stylesheet = {
            paper: {
                minHeight: '50vh'
            },
            pdfBox: {
                height: '25vh'
            },
            label: {
                color: 'gray'
            },
            card: {
                maxWidth: 345
            },
            cardMedia: {
                height: 140
            },
            cardAction: {
                width: '100%',
                justifyContent: 'center'
            }

        }
        const states = Constants.states;

        return (
            <div>

                <Paper variant="outlined" style={stylesheet.paper}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={2}>
                                <Grid item xs={12}>
                                    <Box m={4}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            alignItems="flex-start"
                                            spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography variant="overline">
                                                    <Box display="inline" fontWeight="fontWeightBold" fontSize={24}>Seller Information</Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="overline" noWrap>
                                                    <Box display="inline" fontSize={12}>2 min</Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" align="left">
                                                    <Box display="inline" fontWeight="fontWeightLight">
                                                        This section requires you to provide basic legal details about yourself so we
                                                        can get the paperwork put together.
                                                    </Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FaqSection section="si"/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item xs={12}>
                                            <Box m={4}>
                                                <form className={stylesheet.form} noValidate autoComplete="off">
                                                    <FormControl>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justify="center"
                                                            alignItems="stretch"
                                                            spacing={4}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="overline" align="left">Basic Info</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="space-between"
                                                                    alignItems="center"
                                                                    spacing={2}>
                                                                    <Grid item xs={12} md={6}>
                                                                        <TextField
                                                                            required
                                                                            name="firstname"
                                                                            value={this.state.firstname}
                                                                            onChange={this.handleChange}
                                                                            error={this.state.errors.firstname}
                                                                            id="standard-required"
                                                                            label="First Name"
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            fullWidth/>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <TextField
                                                                            required
                                                                            id="standard-required"
                                                                            label="Last Name"
                                                                            InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                            name="lastname"
                                                                            value={this.state.lastname}
                                                                            onChange={this.handleChange}
                                                                            error={this.state.errors.lastname}
                                                                            fullWidth/>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    required
                                                                    name="email"
                                                                    value={this.state.email}
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.email}
                                                                    id="standard-required"
                                                                    label="Email Address"
                                                                    InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                    fullWidth={true}/>
                                                            </Grid>
                                                            
                                                            <Grid item xs={12}>
                                                                <Typography variant="overline" align="left">Legal Address</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    required
                                                                    name="address1"
                                                                    value={this.state.address1}
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.address1}
                                                                    id="standard-required"
                                                                    label="Address Line 1"
                                                                    InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                    fullWidth={true}/>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    label="Address Line 2"
                                                                    name="address2"
                                                                    value={this.state.address2}
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.address2}
                                                                    InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                    fullWidth={true}/>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="space-between"
                                                                    alignItems="center"
                                                                    spacing={2}>
                                                                    <Grid item xs={12} md={6}>
                                                                        <TextField
                                                                            name="city"
                                                                            value={this.state.city}
                                                                            onChange={this.handleChange}
                                                                            error={this.state.errors.city}
                                                                            id="standard-required"
                                                                            label="City"
                                                                            InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                            fullWidth/>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={3}>
                                                                        <TextField
                                                                            select
                                                                            name="state"
                                                                            value={this.state.state}
                                                                            onChange={this.handleChange}
                                                                            error={this.state.errors.state}
                                                                            label="State"
                                                                            SelectProps={{
                                                                            native: true
                                                                        }}>
                                                                            {states.map((option) => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </TextField>
                                                                    </Grid>
                                                                    <Grid item xs={10} md={3}>
                                                                        <TextField
                                                                            name="zip"
                                                                            value={this.state.zip}
                                                                            onChange={this.handleChange}
                                                                            error={this.state.errors.zip}
                                                                            id="standard-required"
                                                                            label="Zipcode"
                                                                            InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                            fullWidth/>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </FormControl>
                                                </form>
                                            </Box>

                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box ml={4} mb={4}>
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item xs={6}>
                                                <Box m={2}>
                                                    <Button fullWidth onClick={this.props.backHandler}>
                                                        Back
                                                    </Button>
                                                </Box>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box m={2}>

                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        onClick={this.nextPage}>
                                                        Next
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>

            </div>
        );
    }
}

export default withFirebase(SellerInfo);

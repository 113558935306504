import React from 'react';
import NumberFormat from 'react-number-format';

class NumberFormatter extends React.Component{
  constructor(props){
      super(props);
  }
  render(){
    if(this.props.money){
      return(<NumberFormat value={this.props.value} displayType={'text'} thousandSeparator={true} prefix={'$'} />)
    }
    else{
      return(<NumberFormat value={this.props.value} displayType={'text'} thousandSeparator={true} />)
    }
  }
}
export default NumberFormatter;

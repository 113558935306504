import React from "react";

import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";


import {withFirebaseAuth} from '../../../../Firebase'
import authRequest from '../../../../General/AuthRequest';




class Verify extends React.Component{
  constructor(props){
    super(props);
    this.transactionId = this.props.token;
    this.state = {
      verified:""
    };



    this.getLink = this.getLink.bind(this);
    this.pullVerify = this.pullVerify.bind(this);
    this.sendUpState = this.sendUpState.bind(this);
    this.computeIsVerified = this.computeIsVerified.bind(this);


  }
  
  componentDidMount(){ 
    this.pullVerify();
    
  }

  computeIsVerified(){
    return this.state.verified==="SUCCESS"
  }

  sendUpState(){
    this.props.onChange(this.computeIsVerified());
  }
  

  pullVerify(){



    this.props.firebase.user(this.props.authUser.uid).on("value", verifySnapshot=>{
      const verifyInfo = verifySnapshot.val();
      this.setState({
        verified: verifyInfo.verified? verifyInfo.verified : "START"
      }, this.sendUpState);
    });
  }


  async getLink(){
    const idToken = this.props.authUser.uid; //INSECURE, TODO

    // const resp = await fetch('/api/start_identity',
    //   {method: 'POST',
    //   body: JSON.stringify({idToken: idToken, transactionId: this.transactionId})});


    // const respJSON = await resp.json();

    const respJSON = await authRequest("/api/start_identity", "POST", {transactionId: this.transactionId}, this.props.authUser.idToken);
    if(respJSON && respJSON['next_action'] && respJSON["next_action"]["redirect_to_url"]){
      const linkUse = respJSON["next_action"]["redirect_to_url"]; 
      window.location = linkUse;
    }

  }

  render(){

    let controlComponent = this.state.verified === "PENDING"? <Checkbox icon={<CircularProgress size={20}/>} /> : <Checkbox/>


    let message = "Click Verify to get started";
    let buttonMessage = "Verify";

  

    if(this.state.verified === "SUCCESS"){
      message = "Identity Verified!";
      buttonMessage = "Restart Verification Process";
    }else if(this.state.verified === "WRONG"){
      message = "Identities did not match";
      buttonMessage = "Reverify";
    }else if(this.state.verified === "PENDING"){
      message = "Pending. This usually takes just a few minutes but can take up to a few hours in rare cases";
      buttonMessage = "Restart";
    }else if(this.state.verified==="START"){
      message = "Click Verify to get started";
      buttonMessage = "Verify"; 
    }else{
      message = this.state.verified;
    }


    let isVerified = this.computeIsVerified();
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onChange={(event) => event.stopPropagation()}
            checked={isVerified || this.props.alreadyPayed}
            disabled={this.props.alreadyPayed}
            control={controlComponent}
            label="Verify Identity"
          />
        </AccordionSummary>
        <AccordionDetails>

          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Box mt={2}><Button onClick = {this.getLink} disabled={this.props.alreadyPayed}> {buttonMessage} </Button></Box>
            </Grid>
            <Grid item xs={6}>
              <Box mt={2}>
              <Typography variant="body2">
                <Box
                  display="inline"
                  fontWeight="fontWeightLight"
                
                >
                  {message}
                </Box>
              </Typography>
            </Box>

            </Grid>
            
          </Grid>
        </AccordionDetails>
      </Accordion>

    );
  }

}

export default withFirebaseAuth(Verify);

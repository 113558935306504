import React from 'react';

import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import {FormControl, TextField, Button} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withFirebaseAuth} from '../../../Firebase'
import STATES from './StateConstants'
import VALIDATOR from '../../../General/Validator'
import FaqSection from '../../../General/FaqSection'

class BuyerInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            state: 'AL',
            zip: '',
            address1: '',
            address2: '',
            city: '',
            firstname: '',
            lastname: '',
            email: '',
            errors: {},
        };

        //fields that will be validated
        this.validateFields = new Set([
            "state",
            "zip",
            "address1",
            "address2",
            "city",
            "firstname",
            "lastname",
            "email"])

        this.transactionId = this.props.token;
        
        this.handleChange = this.handleChange.bind(this);
        this.fbLoad = this.fbLoad.bind(this);
        this.fbSave = this.fbSave.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.validateInput = this.validateInput.bind(this);

    }


    //returns true if all inputs are valid, displays error messages if not
    validateInput(){

      let foundError = false;
      let errors = {};
      for(let field of this.validateFields){
        
        if(!VALIDATOR[field](this.state[field])){
          foundError = true;
          errors[field] = true;
        }else{
          errors[field] = false
        }
      }
      this.setState({errors: errors})
      return !foundError;
    }


    async fbLoad(){
      return this.props.firebase.userBasicInfo(this.transactionId, this.props.authUser.uid).once("value", snapshot=>{
        if(snapshot.exists()){
          this.setState(snapshot.val())
        }
      });
    }
    
    //if save Errors, then save all of the errors in fb
    async fbSave(){
      let updateObj = {};
      for(let field of this.validateFields){
        updateObj[field] = this.state[field];
      }
      return this.props.firebase.userBasicInfo(this.transactionId, this.props.authUser.uid).update(updateObj);
    }

    componentWillMount(){
      this.fbLoad();

    }
    handleChange(e) {
        let newObj = {};
        newObj[e.target.name] = e.target.value;
        this.setState(newObj);
    }

    //wrapper function for going to the next page
    async nextPage(e){
      e.preventDefault();
      const isValid = this.validateInput();
      await this.fbSave();
      if(isValid){
        this.props.nextHandler(e);
      }
      
    }

    render() {
        

        const stylesheet = {
            paper: {
                minHeight: '50vh'
            },
            form: {
                '& .MuiTextField-root': {
                    margin: '4px'
                }
            }

        }
        return (
            <div>
                <Paper variant="outlined" style={stylesheet.paper}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Box m={4}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-start"
                                    spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography variant="overline">
                                            <Box display="inline" fontWeight="fontWeightBold" fontSize={24}>Personal Information</Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="overline" noWrap>
                                            <Box display="inline" fontSize={12}>2 min</Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">
                                            <Box display="inline" fontWeight="fontWeightLight">We need this information to
                                                help you claim this car listing and begin the purchase process. All your
                                                information is secure using bank-grade security.</Box>
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <Box ml={4} mt={-2} m={2}>
                                            <FaqSection section="pi"/>
                                        </Box>
                                        <Divider/>
                                    </Grid> 

                                </Grid>
                            </Box>
                        </Grid>
                           
                        <Grid item xs={12} md={8}>
                            <Box m={4}>
                                <form className={stylesheet.form} noValidate autoComplete="off">
                                    <FormControl>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            alignItems="stretch"
                                            spacing={4}>
                                            <Grid item xs={12}>
                                                <Typography variant="overline" align="left">Basic Info</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="space-between"
                                                    alignItems="center"
                                                    spacing={2}>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            name="firstname"
                                                            required
                                                            id="standard-required"
                                                            label="First Name"
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            fullWidth
                                                            onChange={this.handleChange}
                                                            value={this.state.firstname}
                                                            error={this.state.errors.firstname}
                                                            />
                                                            
                                                            
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            name="lastname"
                                                            required
                                                            id="standard-required"
                                                            label="Last Name"
                                                            InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                            fullWidth
                                                            onChange={this.handleChange}
                                                            value={this.state.lastname}
                                                            error={this.state.errors.lastname}
                                                            />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    name="email"
                                                    id="standard-required"
                                                    label="Email Address"
                                                    InputLabelProps={{
                                                    shrink: true
                                                }}
                                                    fullWidth={true}
                                                    onChange={this.handleChange}
                                                    value={this.state.email}
                                                    error={this.state.errors.email}/>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography variant="overline" align="left">Legal Address</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="address1"
                                                    required
                                                    id="standard-required"
                                                    label="Address Line 1"
                                                    InputLabelProps={{
                                                    shrink: true
                                                }}
                                                    fullWidth={true}
                                                    onChange={this.handleChange}
                                                    value={this.state.address1}
                                                    error={this.state.errors.address1}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="address2"
                                                    label="Address Line 2"
                                                    InputLabelProps={{
                                                    shrink: true
                                                }}
                                                    fullWidth
                                                    onChange={this.handleChange}
                                                    value={this.state.address2}
                                                    error={this.state.errors.address2}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="space-between"
                                                    alignItems="center"
                                                    spacing={2}>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            name="city"
                                                            required
                                                            id="standard-required"
                                                            label="City"
                                                            InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                            fullWidth
                                                            onChange={this.handleChange}
                                                            value={this.state.city}
                                                            error={this.state.errors.city}/>
                                                    </Grid>
                                                    <Grid item xs={2} md={3}>
                                                        <TextField
                                                            name="state"
                                                            select
                                                            label="State"
                                                            value={this.state.state}
                                                            onChange={this.handleChange}
                                                            error={this.state.errors.state}
                                                            SelectProps={{
                                                            native: true
                                                        }}>
                                                            {STATES.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={10} md={3}>
                                                        <TextField
                                                            name="zip"
                                                            required
                                                            id="standard-required"
                                                            label="Zipcode"
                                                            InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                            fullWidth
                                                            onChange={this.handleChange}
                                                            value={this.state.zip}
                                                            error={this.state.errors.zip}/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    direction="row-reverse"
                                                    justify="flex-start"
                                                    alignItems="center">
                                                    <Grid item xs={8}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            onClick={this.nextPage}>
                                                            Next
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </form>
                            </Box>

                        </Grid>
                        <Divider/>

                    </Grid>
                </Paper>

            </div>
        );
    }
}

export default withFirebaseAuth(BuyerInfo);

import { Button, TextField, FormControl, OutlinedInput, InputLabel, InputAdornment } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ChipInput from 'material-ui-chip-input';
import React from 'react';

import { withFirebase } from '../../../Firebase';
import VALIDATOR from '../../../General/Validator';
import FaqSection from '../../../General/FaqSection'



class Disclosures extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chipData: ['No issues.'],
            price: '',
            priceError: false,
        };
        this.handleAddChip = this
            .handleAddChip
            .bind(this);
        this.handleDeleteChip = this
            .handleDeleteChip
            .bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.fbSave = this.fbSave.bind(this);
        this.validate = this.validate.bind(this);
        
    }

    validate(){
      let isValid = VALIDATOR['price'](this.state.price);
      if(!isValid){
        this.setState({priceError: true});
      }
      return isValid;
    }

    async fbSave(){
      let awaitMe = [];
      awaitMe.push(this.props.firebase.price(this.props.token).set(this.state.price))
      awaitMe.push(this.props.firebase.disclosures(this.props.token).set(this.state.chipData));
      return awaitMe;
    }

    async nextPage(e){
      e.preventDefault();
      await this.fbSave();
      if(this.validate()){
        this.props.nextHandler();
      }
      
    }
    
    componentDidMount() {
        this.props.firebase.disclosures(this.props.token).once('value', snapshot=>{
          if(snapshot.exists()){
            this.setState({chipData: snapshot.val()});
          }
        })

        this.props.firebase.price(this.props.token).once("value", snapshot=>{
          if(snapshot.exists()){
            this.setState({price: snapshot.val()});
          }
        })
    }

    handleAddChip(chip) {
        this.setState({
            chipData: [
                ...this.state.chipData,
                chip
            ]
        });
    }
    handleDeleteChip(chip, index) {

        this.setState({
            chipData: this
                .state
                .chipData
                .filter((c) => c !== chip)
        });
    }

    handlePriceChange(e){
      this.setState({price: e.target.value});
    }

    render() {

        const stylesheet = {
            paper: {
                minHeight: '50vh'
            },
            pdfBox: {
                height: '25vh'
            }

        }

        return (
            <div>

                <Paper variant="outlined" style={stylesheet.paper}>
                <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={12} md={8}>
                        <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={2}>
                        <Grid item xs={12}>
                            <Box m={4}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-start"
                                    spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography variant="overline">
                                            <Box display="inline" fontWeight="fontWeightBold" fontSize={24}>Disclosures + Sales Price</Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="overline" noWrap>
                                            <Box display="inline" fontSize={12}>2 min</Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">
                                            This section allows you to price your car and include important disclosures for
                                            the buyer.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FaqSection section="disclosures"/>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12} >
                            <Box m={4}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-start"
                                    spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">
                                            <Box display="inline">Price</Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">
                                            <Box display="inline" fontSize={12}>This is the sale price that the buyer will
                                                pay your through Crow. The buyer is also responsible for paying a small platform
                                                fee to Crow based on the sale price.
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="stretch"
                                            alignItems="center"
                                            >
                                            <Grid item xs={9}>
                                                <Typography variant="body2">
                                                    How much are you selling your car for?
                                                </Typography>
                                                <Typography variant="caption">
                                                    <Box fontSize={10} mt={1}>
                                                        This will be paid to you in whole after the title transfer.

                                                    </Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                               
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-amount"
                                                        value={this.state.price}
                                                        onChange={this.handlePriceChange}
                                                        error={this.state.priceError}
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                        labelWidth={50}

                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Box m={4}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="stretch"
                                    alignItems="flex-start"
                                    spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">
                                            <Box display="inline">Disclosures</Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">
                                            <Box display="inline" fontSize={12}>
                                                It is important that you fill this section out, as if the seller contests the
                                                sale for any mechanical/transmission issues with the vehicle, these disclosures
                                                ensure that the transaction was executed in good faith. For this reason, it is
                                                also important that you and the buyer take the car to a mechanic and get it
                                                inspected.
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="stretch"
                                            alignItems="center"
                                            spacing={2}>
                                            <Grid item xs={12}>
                                                <ChipInput
                                                    value={this.state.chipData}
                                                    onAdd={(chip) => this.handleAddChip(chip)}
                                                    onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                                                
                                                    variant="outlined"/>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider/>
                        </Grid>

                        <Grid item xs={12}>
                                    <Box ml={4} mb={4}>
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item xs={6}>
                                                <Box m={2}>
                                                    <Button fullWidth onClick={this.props.backHandler}>
                                                        Back
                                                    </Button>
                                                </Box>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box m={2}>

                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        onClick={this.nextPage}>
                                                        Next
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                    </Grid>
                    </Grid>
                    </Grid>
                </Paper>

            </div>
        );
    }
}

export default withFirebase(Disclosures);

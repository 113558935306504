import React from 'react'
import Input from '../elements/Input';
import Button from '../elements/Button';

import { withRouter } from 'react-router-dom';
import {withFirebase, withFirebaseAuth} from '../../../../Firebase'

import SnackbarAlert from '../../../../General/SnackbarAlert'
import authRequest from '../../../../General/AuthRequest'

import MuiPhoneNumber from  'material-ui-phone-number'

import {Alert} from '@material-ui/lab'
import { Hidden, Typography, Link } from '@material-ui/core';

import {Redirect } from 'react-router-dom'
class BuyerLogin extends React.Component {
    constructor(props){
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
       
        
        
        this.state = {
            phone: "",
            transactionID: this.props.id || "",
        }

        
        this.login = this.login.bind(this);
        this.signOut = this.signOut.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    signOut(e){

        this.props.firebase.doSignOut();
    }


    componentDidMount(){
        authRequest(`/api/getphonenumber`, 'GET', {}, this.props.authUser.idToken)
                    .then(
                        (result) => {
                            this.setState(result);
                        }
                    )
    }

    
    handleChange(event){
        let newObj = {};
        newObj[event.target.name] = event.target.value;
        this.setState(newObj)
    }

    login(){
        if(this.props.isBuyer){
            this.props.history.push(`/checkout/${this.state.transactionID}`)
        }else{
            this.props.history.push(`/seller/${this.state.transactionID}`)
        }
    }

    render() {
        
        return (
            <div style={this.props.hide? {display:"none"}:{}}>
                
                <div className="mb-12" style={{marginTop: "15px"}}>

                <Input
                    type="text"
                    value={this.state.phone}
                    name="phone"
                    label="Phone"
                    disabled
                    />

                </div>

                
                
                
                
                <div className="mt-24 mb-32" style={!this.state.awaitingCode? {}: {display: "none"}}>
                    <Button id="sign-in-button-b" onClick={this.login} color="primary" wide>Log In</Button>
                </div>

                <div className="mt-24 mb-32" style={!this.state.awaitingCode? {}: {display: "none"}}>
                    <div className="pt-32 text-xs center-content text-color-low">
                            Change Account?{' '}<Link onClick={this.signOut} className="func-link">Sign Out</Link>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default withRouter(withFirebaseAuth(BuyerLogin));

import React from 'react'

import {Snackbar, SnackbarContent, Typography} from '@material-ui/core'

class SnackBarAlert extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return (
            <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.props.open}
          style={{ opacity: '100%' }}
          onClose={this.props.onClose}
          
          autoHideDuration={600}
        >
          <SnackbarContent
            message={
              <React.Fragment>
                <Typography variant="body2">
                  {this.props.message}
                </Typography>
              </React.Fragment>
            }
            style={{ background: '#4024C4', opacity: '80%' }}
          />
        </Snackbar>
        );

    }
}

export default SnackBarAlert;
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import SnackbarAlert from '../../../General/SnackbarAlert';
import {withFirebase} from "../../../Firebase"
import { Typography } from '@material-ui/core';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


class Buyers extends React.Component{
    constructor(props){
        super(props);
        this.state = {rows:[], alertOpen: false, alertMessage: ""}
        

        this.resend = this.resend.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    //whether next button is active based on anyone having payed
    commputeCanNext(rows){
        for(let row of rows){
          if(row.status==="Paid"){
            return true;
          }
        }
        return false;
    }

    componentDidMount(){
        this.props.firebase.buyerStatus(this.props.token).on("value", snapshot=>{
            if(snapshot.exists()){
                let rows = []
                for (var key in snapshot.val()) {
                    rows.push({
                        phone: key,
                        status: snapshot.val()[key]
                    })
                }
                this.props.onChange(this.commputeCanNext(rows));
                this.setState({rows: rows})
            }
        });
    }


    async resend(e, phone){
        e.preventDefault();
        const resp = await fetch('/api/sendinvite',
          {method: 'POST',
          body: JSON.stringify(
            {
              
              buyerPhone: phone,
              transactionId: this.props.token
          })});
        const respJSON = await resp.json();
        if(respJSON.status === "SUCCESS"){
          this.setState({phone:'', keyv: this.state.keyv+1});
          this.setState({hasSubmitted: true, alertOpen: true, alertMessage: "Invite Resent!"})
        }else{
          alert(JSON.stringify(respJSON));
          this.setState({ alertOpen: true, alertMessage: "Invite Failed."});
          
        }
    }

    render(){
      if(this.props.disabled){
        return <div></div>
      }
        return (
            <TableContainer component={Paper} >
              <Table aria-label="simple table" style={{minWidth:"100%"}}>
                <TableHead>
                  <TableRow >
                    <TableCell style={{paddingLeft:"16px"}}>Phone</TableCell>
                    <TableCell align="right"
                    style={this.props.preventSend? {paddingRight:"16px"}: {}}  >Status</TableCell>

                      {!this.props.preventSend &&
                        <TableCell style={{paddingRight:"16px"}}  align="right">Action</TableCell>
                      }
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.rows.map((row,index) => {
                            return (
                                
                                <TableRow key={index}>
                                    <TableCell style={{paddingLeft:"16px"}} component="th" scope="row">
                                        {row.phone}
                                    </TableCell>
                                    <TableCell style={this.props.preventSend? {paddingRight:"16px"}: {}} align="right">{row.status}</TableCell>
                                    
                                      {!this.props.preventSend && 
                                      <TableCell style={{paddingRight:"16px"}} align="right">
                                        {row.status !== "Paid" &&
                                          <Button onClick={(e)=>{this.resend(e,row.phone)}}variant="contained">Resend</Button>
                                        }
                                      </TableCell>
                                    }
                                  </TableRow>

                            );
                            
                        })}
                    {this.state.rows.length===0 && 
                      <TableRow style={{height:"50px"}}></TableRow>
                    }  
                    
                  
                </TableBody>
              </Table>
              <SnackbarAlert open={this.state.alertOpen} onClose={()=>{this.setState({alertOpen: false})}} message={this.state.alertMessage}/>
            </TableContainer>
          );
    }
}


export default withFirebase(Buyers);
import React from 'react';

import {withAuthorization, AuthUserContext, withAuthentication} from '../Session'
import { compose } from 'recompose';
const condition = authUser => !!authUser;

const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);


export const withFirebaseAuth = Component => props => (
  <FirebaseContext.Consumer>
    {firebase => (
      <AuthUserContext.Consumer>
        {authUser => (
          <Component {...props} firebase={firebase} authUser={authUser} />
        )}
      </AuthUserContext.Consumer>
    )}
  </FirebaseContext.Consumer>
);

export const withFirebaseAuthSecure = Component => {
  return withFirebaseAuth(compose(
    withAuthorization(condition)
  )(Component));
};



export default FirebaseContext;

import React from 'react'

import {List, ListItemText, ListItem, Typography, Divider, IconButton, ListItemSecondaryAction} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// requires prop of history which is dictionary with keys of all owned
// transactions by user

function ListItemLink(props) {
    return (<ListItem button component="a" href={props.href} >
                <ListItemText primary={<Typography variant="overline">{props.primary}</Typography>}/>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="go to" disabled>
                      <NavigateNextIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
            </ListItem>);
  }

class SellHistory extends React.Component {
   

    render() {
        if (!this.props.history || Object.keys(this.props.history).length == 0) {
            return <div></div>
        } else {
            return (
                <div>
                    <Typography variant="overline">
                        Viewing History
                    </Typography>
                    <List component="nav" aria-label="Past History">
                        {this.props.history
                            .map((transaction) => (
                                
                                <div className="mb-12" key={transaction.transID}>
                                    <ListItemLink key={transaction.transID} primary={transaction.name} href={this.props.isCheckout? ('/checkout/' + transaction.transID): ('/sellerflow/' + transaction.transID)}> 
                                    <Alert icon={false} severity="success" fullWidth> {transaction.name} </Alert>
                                </ListItemLink>
                                <Divider/>
                                </div>

                            ))
}
                    </List>
                </div>
            );
        }

    }
}

export default SellHistory;
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DisputeModal(props) {
    const open = props.open;
    const setOpen = props.setOpen;
  const handleClickOpen = () => {
    setOpen();
  };
  const handleClose = () => {
    setOpen();
  };
  var dialogText = "Please describe what issue you're facing with the current transaction. The buyer will know you are launching a dispute, but we will not share the message you send here."
  if(props.isBuyer){
    dialogText="Please describe what issue you're facing with the current transaction. The seller will know you are launching a dispute, but we will not share the message you send here."
  }
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Launch a Dispute
        </DialogTitle>
        <DialogContent dividers>
        <Grid
  container
  direction="column"
  justify="center"
  alignItems="stretch"
  spacing={2}
>
  <Grid item xs={12}>
  <Typography gutterBottom>
    {dialogText}
          </Typography>
  </Grid>
  <Grid item xs={12}>
  <TextField
          id="outlined-multiline-static"
          multiline
          rows={4}
          defaultValue="The issue is..."
          variant="outlined"
          fullWidth
        />
  </Grid>
          
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Launch Dispute
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React from 'react';

//parameters: number, ActionComponent, text
export const IndividualStep = function(props) {
    return (
        <Grid item xs={12} key={`key-${props.number}`}>
            <Box m={2}></Box>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={1}>
                    {props.complete? 
                    <CheckCircleIcon color="primary" style={{fontSize:'50px'}}/>: <RadioButtonUncheckedIcon  color="primary" style={{fontSize:'50px'}}/>}
                </Grid>
                <Grid item xs={9}>
                    <Box m={2}>
                        <Typography variant="body2">

                            {props.text}

                        </Typography>
                    </Box>
                </Grid>
                
                <Grid item xs={2}>
                    {props.ActionComponent}
                </Grid>
            </Grid>
        </Grid>
    );

}

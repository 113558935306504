import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import MuiPhoneNumber from 'material-ui-phone-number';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../../Firebase';
import SnackbarAlert from '../../../../General/SnackbarAlert';
import Button from '../elements/Button';
import Input from '../elements/Input';
import SignInWithGoogleButton from '../elements/SignInWithGoogleButton';




class SellerLogin extends React.Component {
    constructor(props){
        super(props);
        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resend = this.resend.bind(this);
        this.confirmLogin = this.confirmLogin.bind(this);
        this.setPhone = this.setPhone.bind(this);
        this.signInWithGoogle = this.signInWithGoogle.bind(this);

        this.captchaNeedsToBeReset = false;
        this.state = {
            phone: "+1480444",
            code: "",
            transactionID: this.props.id || "",
            awaitingCode: false,
            error: null,
            redirect: null,
            alertOpen: false
        }

        this.nextURL = '/seller';
        if(this.props.id){
            this.nextURL += "/" + this.props.id
        }

    }

    signInWithGoogle(){
        this.props.firebase.auth.signInWithPopup(this.props.firebase.googleProvider).then((result)=> {
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            this.props.history.push(this.nextURL);
            // ...
          }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
    }
    resend(e){
        e.preventDefault();

        this.setState({
            code: "",
            awaitingCode: true,
            error: null     
        });
        
        // Or, if you haven't stored the widget ID:
        // window.recaptchaVerifierS.render().then((widgetId)=>{
        //     window.grecaptcha.reset(widgetId);
            this.login();
        // });
        
    }
    confirmLogin(e){
        e.preventDefault();
        
        if(this.confirmationResult && this.state.code && this.state.code.length > 0){
            this.confirmationResult.confirm(this.state.code).then(()=>{
                this.props.firebase.analytics.logEvent('seller_logged_in'); 
                this.props.history.push(this.nextURL);
            }).catch(e => {
                this.setState({error: {message: "Unable to confirm code"}});
            })
        }else{
            this.setState({error: {message: "Could not Validate Phone number and code"}});
        }
    }

    handleChange(event){
        
        let newObj = {};
        if(event.target.name==="code"){
            newObj[event.target.name] = event.target.value.replace(/[^0-9+]/g,'');
        }else{
            newObj[event.target.name] = event.target.value;
        }
        
       
        this.setState(newObj)
    }

    setPhone(val){
        this.setState({phone: val})
    }

    async login(e){
        if(e){
            e.preventDefault();
        }
        
        
        if(!window.recaptchaVerifierS){
            window.recaptchaVerifierS = new this.props.firebase.auth_.RecaptchaVerifier('sign-in-button-s', {
                'size': 'invisible',
                'callback': function(response) {
                  // reCAPTCHA solved, allow signInWithPhoneNumber.
                  
                }
              });
        }else{
            const widgetId = await window.recaptchaVerifierS.render();
            window.grecaptcha.reset(widgetId);
        }
        


        const recaptchaVerifier = window.recaptchaVerifierS;
        this.props.firebase.auth.signInWithPhoneNumber(this.state.phone, recaptchaVerifier)
            .then( (confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            this.confirmationResult = confirmationResult;
            
            this.setState({
                awaitingCode: true,
                error: null,
                alertOpen: true
            })
            
            
            }).catch(e => {
                this.setState({error: e})
            })
    }

    formatError(e){
        if(e.code === "auth/invalid-phone-number"){
            return "Invalid Phone Number";
        }
        

        if(e.message){
            return e.message;
        }

        return "Unknown Error"
    }

    render() {
        return (
            <div style={this.props.hide? {display:"none"}:{}}>
               

                <div className="mb-12" style={{marginTop:"15px"}}>

                <MuiPhoneNumber
                    defaultCountry={'us'}
                    onChange={this.setPhone}
                    fullWidth
                    InputProps={{
                        className: this.props.inputClasses,
                    }}
                />
                </div>

                {!!this.props.id && false && 
                <div className="mb-12">

                <Input
                    type="text"
                    value={this.state.transactionID}
                    name="transactionID"
                    onChange={this.handleChange}
                    label="Sale Code (Optional)"
                    disabled={!!this.props.id}
                    />
                </div>
                }

                
                {!this.state.awaitingCode &&
                <div className="mb-12">
                    <div id="recaptcha-container"></div>
                </div>
                 }
                {this.state.awaitingCode &&
                <div className="mb-12">

                    <Input
                        type="number"
                        value={this.state.code}
                        name="code"
                        onChange={this.handleChange}
                        label="Confirmation Code"
                        placeholder="123456"
                        labelHidden/>
                </div>
                }  
                
                
                
                <div className="mt-24 mb-32" style={!this.state.awaitingCode? {}: {display: "none"}}>
                    <Button id="sign-in-button-s" onClick={this.login} color="primary" wide>Send One Time Password</Button>
                </div>
                {this.state.awaitingCode &&
                <div>
                    <div className="mt-24 mb-32">
                        <Button onClick={this.confirmLogin} color="primary" wide>Login</Button>
                    </div>
                    < div className="mt-24 mb-32">
                        <Button onClick={this.resend} wide>Resend Code</Button>
                    </div>
                </div>
                }
                
                <div className="mt-24 mb-32">
                        <Typography variant="overline">
                            <Box m="auto" align="center">
                            or
                            </Box>
                        </Typography>
                    </div>

                <div className="mb-12">
                    <SignInWithGoogleButton nexturl={this.nextURL}/>
                </div>


                {!!this.state.error && 
                <div className="mt-24 mb-32">
                    <Alert severity="warning">{this.formatError(this.state.error)}</Alert>
                </div>
                }
                <SnackbarAlert message={"Code Sent"} open={this.state.alertOpen} onClose={()=>{this.setState({alertOpen:false})}}/>
            </div>
        );
    }
}

export default withRouter(withFirebase(SellerLogin));

import React from 'react';
import FaqSection from '../components/sections/FaqSection';

class Faq extends React.Component {
  render() {
    return (
      <FaqSection className="illustration-section-01" />
    );
  }
}

export default Faq;
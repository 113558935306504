import { Button } from '@material-ui/core';
import React from 'react';
import {IndividualStep} from '../../../../General/NextSteps';
import BillOfSale from '../../../../General/BillOfSale/DocWrapper';
import LinkAccount from '../LinkAccount';
import Title from '../Title';
import directions from './directionsByState';

const stylesheet = {
    paper: {

        minHeight: '50vh'
    },
    pdfBox: {
        height: '25vh'
    },
    numberAvatar: {
        color: '#360D73'
    }

};

class Steps extends React.Component{
    constructor(props){
        super(props);

        this.state = {
             bill: false,
             title: false,
             stripe: false, //booleans to track the completion of all steps
        }
        this.setBill = this.setBill.bind(this);
        this.setTitle = this.setTitle.bind(this);
        this.setStripe = this.setStripe.bind(this);
        this.callOnChange = this.callOnChange.bind(this);
    }

    setBill(val){
        this.setState({bill: val}, this.callOnChange);
    }
    setTitle(val){
        this.setState({title: val}, this.callOnChange);
    }
    setStripe(val){
        this.setState({stripe: val}, this.callOnChange);
    }
    
    callOnChange(){
        this.props.onChange(this.state.bill && this.state.title && this.state.stripe);
    }


    getComponent(button){
        if (!button) {
            return null;
        }
        if (button.url) {
            return <Button href={button.url}>{button.text}</Button>
        } else if (button.review) {
            return <BillOfSale token={this.props.token} onChange={this.setBill} isSeller/>;
        } else if (button.upload) {
            return <Title token={this.props.token} onChange={this.setTitle}/>
        } else {
            return null;
        }
    }
    //returns whether this step is complete based on state
    getComplete(button){
        if (!button) {
            return true;
        }
        if (button.url) {
            return <Button href={button.url}>{button.text}</Button>
        } else if (button.review) {
            return this.state.bill;
        } else if (button.upload) {
            return this.state.title;
        } else {
            return true;
        }
    }

    
    
    render(){
        if (!this.props.sellerState || !directions[this.props.sellerState] || !directions[this.props.sellerState].steps) {
            return <h4>We were unable to find next steps for your state</h4>;
        }

        return (
            <React.Fragment>
                {directions[this.props.sellerState]
                    .steps
                    .map((step, index) => {
                        return (
                        <React.Fragment key={`key-${index}`}>
                            <IndividualStep
                            complete={this.getComplete(step.button)}
                            number={index + 1}
                            text={step.text}
                            ActionComponent={this.getComponent(step.button)}/>
                        </React.Fragment>
                        );
                    })}
                <IndividualStep
                    complete={this.state.stripe}
                    number={directions[this.props.sellerState].steps.length + 1}
                    text=" Please create an account with our partner Stripe to withdraw the funds for the
                    car."
                    ActionComponent={< LinkAccount 
                        token = {this.props.token}
                        onChange = {this.setStripe}
                    />}/>
    
            </React.Fragment>
        );
    }

}


export default Steps;
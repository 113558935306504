import React from 'react'


import {TextField, IconButton} from '@material-ui/core'
import SnackbarAlert from '../../../General/SnackbarAlert'; 

import FilterNoneIcon from '@material-ui/icons/FilterNone';
class CopyLink extends React.Component {
    constructor(props){
        super(props);
        this.copy = this.copy.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.state={
            alertOpen: false,
            alertMessage: '',
        }
        this.warn = this.warn.bind(this);
    }

    copy(){
       window.navigator.clipboard.writeText(window.location.origin  + "/buyerreferral/" + this.props.token);
        this.setState({alertOpen: true, alertMessage: "Copied!"})
    }

    closeAlert(){
        this.setState({alertOpen: false})
    }

    warn(){
        if(this.props.disabled){
            this.setState({alertOpen: true, alertMessage: "Confirm before Inviting"});
          }
    }


    render() {

        return (
            <div style={{minWidth:"410px"}} onClick={this.warn}>
                <TextField
                    size="small"
                    id="link-input"
                    label="Link"
                    value={window.location.origin  + "/buyerreferral/" + this.props.token}
                    variant="outlined"
                    disabled
                    fullWidth={true}
                    InputProps={{
                    endAdornment: 
                    <IconButton disabled={this.props.disabled} color="primary" aria-label="upload picture" component="span" onClick={this.copy}>
                        <FilterNoneIcon/>
                    </IconButton>
                }}/>
                <SnackbarAlert open={this.state.alertOpen} message={this.state.alertMessage} onClose={this.closeAlert}/>
            
            </div>

        );
    }
}


export default CopyLink;
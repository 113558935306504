import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button
} from '@material-ui/core'

import SignatureCanvas from 'react-signature-canvas'
import {withFirebase} from '../../Firebase';

class Signature extends React.Component {
    constructor(props) {
        super(props);
       
        this.saveSignature = this.saveSignature.bind(this); 
        this.clearCanvas = this.clearCanvas.bind(this); 
    }
 
    saveSignature(){
        const image = this.sigCanvas.toDataURL('png');
        if(this.props.isSeller){
            if(this.sigCanvas.isEmpty()){
                this.props.firebase.signatures(this.props.token).update({seller:"NONE"});
            }else{
                this.props.firebase.signatures(this.props.token).update({seller:image});
            }  
        }else{
            if(this.sigCanvas.isEmpty()){
                this.props.firebase.signatures(this.props.token).update({buyer:"NONE"});
            }else{
                this.props.firebase.signatures(this.props.token).update({buyer:image});
            }
        }
        
        
        this.props.handleAdvance();
        
    }

    clearCanvas(){
        this.sigCanvas.clear();
    }


    render() {
        return (
            <React.Fragment>
                <DialogContent dividers>
                        <SignatureCanvas
                        canvasProps={{width: 500, height: 200}} 
                        ref={(ref) => { this.sigCanvas = ref }} 
                        />
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.clearCanvas} color="primary">
                        Clear
                    </Button>
                    <Button autoFocus onClick={this.saveSignature} color="primary">
                        Adopt And Sign
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}

export default withFirebase(Signature);
import React from 'react'

import {Button, FormControl, TextField} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const stylesheet = {
    paper: {
        minHeight: '50vh'
    },
    form: {
        '& .MuiTextField-root': {
            margin: '4px'
        }
    }

}

export const Title = function (props) {

    return (
        <Grid item xs ={12}>
            <Box m={4}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    spacing={0}>
                    <Grid item xs={12}>
                        <Typography variant="overline">
                            <Box display="inline" fontWeight="fontWeightBold" fontSize={24}>{props.title}</Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="overline" noWrap>
                            <Box display="inline" fontSize={12}>{props.minutes} min</Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">
                            <Box display="inline" fontWeight="fontWeightLight">
                                {props.description}
                            </Box>
                        </Typography>
                    </Grid>
                    {!!props.children && 
                    <Grid item xs={12}>
                        {props.children}
                    </Grid>
}
                </Grid>
            </Box>
            < Divider/>
        </Grid>
    );
}

export const Content = function (props) {

    return (
        <Grid item xs={12}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}>
                
                    <Box m={4}>
                        {props.children}
                    </Box>

                

            </Grid>
        </Grid>
    );
}

export const Page = function (props) {
    return (
        <Paper variant="outlined" style={stylesheet.paper}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}>
                <Grid item xs={12} md={8}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={2}>

                        {props.children}
                    </Grid >
                </Grid>
            </Grid>
        </Paper >
    );
}

export default {
    Title, Page, Content
}
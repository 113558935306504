import React from 'react';
import LoginForm from '../components/sections/LoginForm';
import Seller from '../components/sections/Seller';

class SellerDashboard extends React.Component {
  render() {
    return (
      <Seller className="illustration-section-01" />
    );
  }
}

export default SellerDashboard;

import React from 'react'
import {withFirebaseAuthSecure} from '../../../../Firebase'
import {Button, Grid, Typography, Box, ListItem, ListItemText, List, LinearProgress, FormControlLabel,AccordionDetails,AccordionSummary,Accordion, Divider, Checkbox  } from '@material-ui/core';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import authRequest from '../../../../General/AuthRequest';


// Usage
class Link extends React.Component{
  constructor(props){
    super(props);
    // this.linkHandler = {open: ()=>{alert("loading")}}

    this.state = {isLoading: true, accountLinked: false};

    this.transactionId = this.props.token;

    this.initPlaid = this.initPlaid.bind(this);
    this.linkAccount = this.linkAccount.bind(this);

  }

  componentDidMount(){

    this.props.firebase.bankInfo(this.transactionId, this.props.authUser.uid).on('value', (values)=>{
      this.setState({
        accountLinked: values.exists(),
        bankInfo: values.val(),
        isLoading: false
      });
      this.props.onChange(values.exists());
    });
  }

  async fetchLinkToken(){


    const idToken = this.props.authUser.uid; //INSECURE, TODO

    // const resp = await fetch('/api/get_link_token',
    // {method: 'POST',
    // body: JSON.stringify({idToken: idToken})});
    // const respJSON = await resp.json();
    const respJSON = await authRequest("/api/get_link_token", "POST", {}, this.props.authUser.idToken);
    return respJSON["link_token"];


  }

  async linkAccount(publicToken, metadata){
      const payload = {
        publicToken: publicToken,
        bankInfo: metadata,
        transactionId: this.transactionId
      }
      // const resp = await fetch('/api/save_buyer_bank',
      //   {method: 'POST',
      //   body: JSON.stringify(payload)});

      // const respJSON = await resp.json();
      const respJSON = await authRequest("/api/save_buyer_bank", "POST", payload, this.props.authUser.idToken);
      return respJSON;

  }
  async initPlaid(){



    var linkHandler = window.Plaid.create({
      // Make a request to your server to fetch a new link_token.
      token: await this.fetchLinkToken(),
      onLoad: function() {
          // The Link module finished loading.
      },
      onSuccess: (public_token, metadata) => {
        // The onSuccess function is called when the user has
        // successfully authenticated and selected an account to
        // use.
        //
        // When called, you will send the public_token
        // and the selected account ID, metadata.account_id,
        // to your backend app server.
        //
        // sendDataToBackendServer({
        //   public_token: public_token,
        //   account_id: metadata.account_id
        // });
        console.log('Public Token: ' + public_token);
        console.log('Customer-selected account ID: ' + metadata.account_id);
        console.log(metadata)
        this.setState({
          bankInfo: metadata,
          accountLinked: true
        })
        this.props.onChange(true);
        this.linkAccount(public_token, metadata)
      },
      onExit: function(err, metadata) {
        // The user exited the Link flow.
        if (err != null) {
            // The user encountered a Plaid API error
            // prior to exiting.
        }
        // metadata contains information about the institution
        // that the user selected and the most recent
        // API request IDs.
        // Storing this information can be helpful for support.
      },
    });
    linkHandler.open();

  }


  render(){






    let accountString = "None";
    if(this.state.accountLinked){
      accountString = this.state.bankInfo.institution.name + " | " + this.state.bankInfo.accounts[0].name + " XXXXXXXX"+this.state.bankInfo.accounts[0].mask;
    }

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onChange={(event) => event.stopPropagation()}
            checked={this.state.accountLinked || this.props.alreadyPayed}
            control={<Checkbox/>}
            disabled={this.props.alreadyPayed}
            label="Link Bank Details"
          />
        </AccordionSummary>
        <AccordionDetails>
          {this.state.isLoading && <LinearProgress/>}
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={3}>
              <Typography variant="overline">
                Account
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" noWrap>
                <Box
                  display="inline"
                  fontWeight="fontWeightLight"
                >
                  {accountString}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button onClick = {this.initPlaid} disabled={this.props.alreadyPayed}> {this.state.accountLinked? "Relink" : "Link"} </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>


    );

  }
}

export default withFirebaseAuthSecure(Link);

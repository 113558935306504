import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import * as ROUTES from '../../../constants/routes';
// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';
import LayoutLoggedIn from './layouts/LayoutLoggedIn';


// Views
import Home from './views/Home';
import Secondary from './views/Secondary';
import Login from './views/Login';
import Signup from './views/Signup';
import GetStarted from './views/GetStarted';
import Dashboard from './components/sections/Dashboard';
import GetStartedBuyer from './views/GetStartedBuyer';
import GetStartedSeller from './views/GetStartedSeller';
import BuyerReferralPage from './views/BuyerReferralPage';
import Faq from './views/Faq';
import About from './views/About';

import './assets/scss/style.scss';
import SellerDashboard from './views/SellerDashboard';
import BuyerDashboard from './views/BuyerDashboard';

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/about" component={About} layout={LayoutDefault} />

            <AppRoute exact path="/secondary" component={Secondary} layout={LayoutAlternative} />
            <AppRoute exact path="/login" component={Login} layout={LayoutSignin} />

            <AppRoute exact path="/getstarted/buyer" component={GetStartedBuyer} layout={LayoutSignin} />
            <AppRoute exact path="/getstarted/seller" component={GetStartedSeller} layout={LayoutSignin} />
            
            <AppRoute exact path="/sellerreferral/:id" component={GetStartedSeller} layout={LayoutAlternative} />
            <AppRoute exact path="/buyerreferral/:id" component={BuyerReferralPage} layout={LayoutAlternative} />

            <AppRoute exact path="/getstarted" component={GetStarted} layout={LayoutSignin} />
             <AppRoute exact path="/dashboard" component={Dashboard} layout={LayoutSignin} />
             <AppRoute exact path="/seller" component={SellerDashboard} layout={LayoutLoggedIn} />
             <AppRoute exact path="/checkout" component={BuyerDashboard} layout={LayoutLoggedIn} />

              <AppRoute path={ROUTES.SELLER_TOKEN} component={SellerDashboard} layout={LayoutLoggedIn} />
            <AppRoute exact path="/signup" component={Signup} layout={LayoutSignin} />
            <AppRoute exact path="/faq" component={Faq} layout={LayoutDefault} />
          </Switch>
        )} />
    );
  }

}

export default withRouter(props => <App {...props} />);

export default {
    state: val=>{
        return !!val;
    },
    zip: val=>{
        return /^[\d-]+$/.test(val) && val.length >= 4;
    },
    address1: val=>{
        return !!val;
    },
    address2: ()=>true,
    city: val=>{
        return !!val;
    },
    firstname: val=>{
        return !!val;
    },
    lastname: val=>{
        return !!val;
    },
    email: val=>{
        var re = /\S+@\S+\.\S+/;
        return re.test(val);
    },
    required: val=>{
        return !!val;
    },
    price: val=>{
        try{
            let n = parseInt(val);
            return n && n > 0;
        }catch(e){
            return false;
        }
    }
}
import React from 'react';
import {Alert} from '@material-ui/lab';
import {Box} from '@material-ui/core';

import {withFirebase} from '../../../Firebase';

class PaymentStatus extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            hasPayed: false
        }
        this.loadPaymentStatus = this.loadPaymentStatus.bind(this);
    }

    componentDidMount(){
        this.loadPaymentStatus();
    }
     
    async loadPaymentStatus(){
        const hasPayedSnapshot = await this.props.firebase.paymentTransferred(this.props.token).once('value');
        this.setState({
            hasPayed: !!hasPayedSnapshot.val(),
            isLoading: false,
        });
    }

    render(){
        if(this.state.isLoading){
            return null;
        }

        if(this.state.hasPayed){
            return (
                <Box m={2}>
                    <Alert severity="success"> The seller has received your payment. You are done! </Alert>
                </Box>
            );
        }else{
            return (
                <Box m={2}>
                    <Alert severity="info"> Your payment is currently being held in escrow. If there is an issue file a dispute before 2 days have elapsed</Alert>
                </Box>
            );
       }
        
    }
}

export default withFirebase(PaymentStatus);
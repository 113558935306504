import React from 'react'
import MuiPhoneNumber from 'material-ui-phone-number';
import {Button, Grid} from '@material-ui/core'

import SnackbarAlert from '../../../General/SnackbarAlert';
class Invite extends React.Component{
    constructor(props){
        super(props);
        this.state = {phone: '', keyv: 0, alertOpen: false, alertMessage:""}
        this.updatePhone = this.updatePhone.bind(this);
        this.sendText = this.sendText.bind(this);
        this.warn = this.warn.bind(this);
    } 

    updatePhone(val){
        this.setState({phone: val});
    }
    warn(){
      if(this.props.disabled){
        this.setState({alertOpen: true, alertMessage: "Please confirm before inviting!"});
      }
      
    }
    async sendText(e){
        e.preventDefault();
        
        if(this.state.phone.length > 14){
          
        
          const resp = await fetch('/api/sendinvite',
            {method: 'POST',
            body: JSON.stringify(
              {
                
                buyerPhone: this.state.phone,
                transactionId: this.props.token
            })});
          const respJSON = await resp.json();
          
          if(respJSON.status === "SUCCESS"){
            this.setState({phone:'', keyv: this.state.keyv+1});
            this.setState({hasSubmitted: true, alertOpen: true, alertMessage: "Invite Sent!"})
          }else{
            alert(JSON.stringify(respJSON));
            this.setState({ alertOpen: true, alertMessage: "Invite Failed."});
            
          }
        }else{
          this.setState({alertOpen: true, alertMessage: "Phone Number too short."})
        }
        
    }

    render(){
        return (
            
              <div onClick={this.warn}>
                <Grid container direction="row" spacing={2} justify="center" alignItems="center">
                  <Grid item xs={8}>
                  <MuiPhoneNumber disabled={this.props.disabled} key={this.state.keyv} onChange={this.updatePhone} defaultCountry={'us'} variant="outlined" />
                  </Grid>
                  <Grid item xs={4}>
                  <Button size="small" disabled={this.props.disabled} variant="contained" color = "primary" onClick={this.sendText}>Send</Button>

                  </Grid>
                </Grid>
                <SnackbarAlert autoHideDuration={4000} open={this.state.alertOpen} onClose={()=>{this.setState({alertOpen: false})}} message={this.state.alertMessage}/>

              </div>
            
        
        );
    }
}

export default Invite
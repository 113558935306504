import React from 'react';
import GetStartedPage from '../components/sections/GetStartedPageSeller';

class Login extends React.Component {
  render() {

    return (
      <GetStartedPage id={this.props.match.params.id} className="illustration-section-01" />
    );
  }
}

export default Login;
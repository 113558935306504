export default {
    states: [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ],
    carMakes: [
      "Abarth",
      "Alfa Romeo",
      "Aston Martin",
      "Audi",
      "Bentley",
      "BMW",
      "Bugatti",
      "Cadillac",
      "Chevrolet",
      "Chrysler",
      "Citroën",
      "Dacia",
      "Daewoo",
      "Daihatsu",
      "Dodge",
      "Donkervoort",
      "DS",
      "Ferrari",
      "Fiat",
      "Fisker",
      "Ford",
      "Honda",
      "Hummer",
      "Hyundai",
      "Infiniti",
      "Iveco",
      "Jaguar",
      "Jeep",
      "Kia",
      "KTM",
      "Lada",
      "Lamborghini",
      "Lancia",
      "Land Rover",
      "Landwind",
      "Lexus",
      "Lotus",
      "Maserati",
      "Maybach",
      "Mazda",
      "McLaren",
      "Mercedes-Benz",
      "MG",
      "Mini",
      "Mitsubishi",
      "Morgan",
      "Nissan",
      "Opel",
      "Peugeot",
      "Porsche",
      "Renault",
      "Rolls-Royce",
      "Rover",
      "Saab",
      "Seat",
      "Skoda",
      "Smart",
      "SsangYong",
      "Subaru",
      "Suzuki",
      "Tesla",
      "Toyota",
      "Volkswagen",
      "Volvo"
    ]
}
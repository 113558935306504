import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';


const LayoutDefault = ({ children }) => (
  <React.Fragment>
    <Header navPosition="right" />
    <main className="site-content" style={{minHeight:"93vh"}}>
      {children}
    </main>
    <Footer style={{minHeight:"10vh"}}/>

  </React.Fragment>
);

export default LayoutDefault;  
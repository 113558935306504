import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import Clients from '../components/sections/Clients';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Testimonial from '../components/sections/Testimonial';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import LandingPageSteps from '../components/sections/LandingPageSteps';
import GenericSection from '../components/sections/GenericSection';
import Image from '../components/elements/Image';
import Typography from '@material-ui/core/Typography';
class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <Typography variant="overline">About Us</Typography>
            <h2 className="mt-0">We built Crow for ourselves.</h2>
            <p>
            It wasn’t too long ago when Stuart and I were trying to buy cars from private sellers we met on Facebook. We learned the inherent distrust in meeting someone online and paying them a large sum of money turns a lot of people away, so we began building a service that handles a problem we directly faced.             </p>
            <p>
            We saw this problem relates to many across different types of buyers, whether its classic/vintage cars found in online auctions, or premium cars being sold by owner where diligence and trust is required. We are fortunate to work numerous partners in the automotive space to make the car buying experience for private parties less challenging.             </p>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/image-placeholder.png')}
                alt="Placeholder"
                width={712}
                height={400} />
              <figcaption className="text-color-low">A super-nice image <span role="img" aria-label="mile">😀</span></figcaption>
            </figure>
            <h4>Flexibility</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <ul>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
            </ul>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
            </p>
          </div>
        </GenericSection>
      </React.Fragment>
    );
  }
}

export default About;
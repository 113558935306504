import React from "react";

import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Hidden from "@material-ui/core/Hidden";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";


import NumberFormatter from '../../../../General/NumberFormatter'

import {withFirebaseAuth} from '../../../../Firebase'

class Review extends React.Component{
  constructor(props){
    super(props);
    this.transactionId = this.props.token;
    
    this.state = {reviewed:false, price: {base: 0, fee: 0, total: 0}}
    

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event){
    this.setState({reviewed: event.target.checked});
    this.props.firebase.reviewedPayment(this.transactionId, this.props.authUser.uid).set(event.target.checked);
    this.props.onChange(event.target.checked);
  }

  componentDidMount(){
    this.props.firebase.reviewedPayment(this.transactionId, this.props.authUser.uid).once("value").then(snapshot =>{
      const sval = snapshot.val();
      this.setState({reviewed: sval});
      this.props.onChange(sval);
    });
    

    fetch(`/api/getprice/${this.props.token}`)
      .then(response => response.json())
      .then(data => {
        this.setState({price: data});
        });

      
  }

  
  render(){
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions2-content"
          id="additional-actions2-header"
        >
          <FormControlLabel
            aria-label="Review Payment Details"
            onClick={event=>event.stopPropagation()}
            onChange={this.handleChange}
            checked={this.state.reviewed || this.props.alreadyPayed}
            control={<Checkbox />}
            disabled={this.props.alreadyPayed}
            label="Review Payment Details"
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing="2"
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item lg={3} md={6}>
                  <Typography variant="overline">
                    Car Price
                  </Typography>
                </Grid>
                <Grid item lg={3} md={6}>
                  <Typography variant="overline" noWrap>
                    <Box
                      display="inline"
                      fontWeight="fontWeightLight"
                    >
                      <NumberFormatter money value={this.state.price.base}/>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item lg={6} md={0}>
                  <Hidden mdDown>
                    <Typography variant="body2">
                      <Box
                        display="inline"
                        fontWeight="fontWeightLight"
                      >
                        This is the amount paid to the seller.
                      </Box>
                    </Typography>
                    </Hidden>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item lg={3} md={6}>
                  <Typography variant="overline">
                    Platform Fee
                  </Typography>
                </Grid>
                <Grid item lg={3} md={6}>
                  <Typography variant="overline" noWrap>
                    <Box
                      display="inline"
                      fontWeight="fontWeightLight"
                    >
                      <NumberFormatter money value={this.state.price.fee}/>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item lg={6} md={0}>
                  <Hidden mdDown>
                  <Typography variant="body2">
                      <Box
                        display="inline"
                        fontWeight="fontWeightLight"
                      >
                        This is the amount paid to Crow for
                        handling this transaction securely.
                      </Box>
                    </Typography>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item lg={3} md={6}>
                  <Typography variant="overline">
                    <Box
                      display="inline"
                      fontWeight="fontWeightBold"
                    >
                      Total Payment
                    </Box>
                  </Typography>
                </Grid>
                <Grid item lg={3} md={6}>
                  <Typography variant="overline" noWrap>
                    <Box
                      display="inline"
                      fontWeight="fontWeightLight"
                    >
                      <NumberFormatter money value={this.state.price.total}/>

                    </Box>
                  </Typography>
                </Grid>
                <Grid item lg={6} md={0}>
                  <Hidden mdDown>
                    <Typography variant="body2">
                      <Box
                        display="inline"
                        fontWeight="fontWeightLight"
                      >
                        This is the amount you owe.
                      </Box>
                    </Typography>
                    </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withFirebaseAuth(Review);
